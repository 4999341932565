import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Language from "../views/languageSet.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: '/shopHome',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/login.vue')
      },
      {
        path: '/forget',
        name: 'Forget',
        component: () => import('../views/login/forget.vue')
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/login/register.vue')
      },
      {
        path: '/mineOrder',
        name: 'mineOrder',
        component: () => import('../views/mine/order.vue')
      },
      {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('../views/order/orderDetail.vue')
      },

      {
        path: '/account',
        name: 'account',
        component: () => import('../views/mine/account.vue')
      },
      {
        path: '/address',
        name: 'address',
        component: () => import('../views/mine/address.vue')
      },
      {
        path: '/editAddress',
        name: 'editAddress',
        component: () => import('../views/mine/editAddress.vue')
      },
      {
        path: '/shopHome',
        name: 'ShopHome',
        component: () => import('../views/shop/home.vue')
      },
      {
        path: '/shopDetail',
        name: 'ShopDetail',
        component: () => import('../views/shop/detail.vue')
      },
      {
        path: '/confirmOrder',
        name: 'ConfirmOrder',
        component: () => import('../views/order/confirmOrder.vue')
      },
      {
        path: '/shopCart',
        name: 'ShopCart',
        component: () => import('../views/shop/cart.vue')
      },
      {
        path: '/areacode',
        name: 'areacode',
        component: () => import('../views/login/areacode.vue')
      },
      {
        path: '/support',
        name: 'support',
        component: () => import('../views/mine/support.vue')
      },
      {
        path: '/payingnbhd',
        name: 'payingnbhd',
        component: () => import('../views/order/paying.vue')
      },
      {
        path: '/payingnbhdResult',
        name: 'payingnbhdResult',
        component: () => import('../views/order/payingResult.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/login/privacy.vue')
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: () => import('../views/login/userAgreement.vue')
      },
    ]
  },
  {
    path: "/language",
    name: "language",
    component: Language,
  },
  // 其他路由配置...
  {
    path: '/:pathMatch(.*)', // 使用通配符 * 匹配所有路由
    redirect: '/', // 将所有路由重定向到 /home
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// 无语言选择情况下优先去语言选择页面
router.beforeEach((to, from, next) => {
  const language = localStorage.getItem("language");
  if (!language || language.trim() === "") {
    if (to.name !== "language") {
      next({ name: "language" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
