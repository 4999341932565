<template>
	<div class="homeContainer">
		<div class="routerView" id="mainRouterView">
			<router-view v-slot="{ Component }">
				<keep-alive>
					<component :is="Component" :key="route.name" v-if="route.meta.keepAlive"></component>
				</keep-alive>
				<component :is="Component" :key="route.name" v-if="!route.meta.keepAlive"></component>
			</router-view>
		</div>
	</div>
</template>

<script setup>
import { useRoute } from "vue-router";
const route = useRoute();
console.log("标识", route);
</script>

<style lang="less">
* {
	box-sizing: border-box;
}
.homeContainer {
	width: 100%;
	height: 100%;
}
.routerView {
	height: 100%;
	overflow: auto;
}
/* 修改垂直滚动条 */
::-webkit-scrollbar {
	width: 0px; /* 修改宽度 */
}

/* 修改滚动条轨道背景色 */
::-webkit-scrollbar-track {
	background-color: #000000;
}

/* 修改滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
	background-color: #888;
}
</style>
