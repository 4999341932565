export const message = {
	language: "TW",
	mine:{
		mine1:'我的訂單',
		mine2:'賬戶設置',
		mine3:'我的地址',
		mine4:'客戶支持',
		mine5:'退出登錄',
		order1:'全部訂單',
		order2:'待支付',
		order3:'待發貨',
		order4:'已完成',
		order11:'待收貨',
		order12:'已關閉',
		finished:'沒有更多了',
		loading:'加載中...',
		nodata:'暫無相關訂單',
		order5:'訂單編號',
		order6:'款式',
		order7:'數量',
		order8:'共',
		order9:'件',
		order10:'總計',
		pay1:'5分鍾內訂單自動關閉',
		pay2:'修改支付方式',
		pay3:'立即支付',
		pay4:'確定支付',
		pay5:'請選擇支付方式',
		pay6:'售後支持',
		pay7:'查看物流',
		log1:'您的物流信息可前往物流服務商官網輸入運單號進行查詢',
		log2:'物流服務商',
		log3:'物流單號',
		log4:'確定',
		after1:'在線時間：10:30 - 18:00（法定節假日除外）',
		copy:'複制成功',
		detail1:'請繼續支付，5分鍾內未付款，訂單將自動取消',
		detail2:'訂單詳情',
		detail3:'商品總額',
		detail4:'快遞費',
		detail5:'支付平台手續費',
		detail6:'訂單號',
		detail7:'信息摘要',
		detail8:'狀態',
		detail9:'支付方式',
		detail10:'寄送至',
		detail11:'訂單日期',
		confirm1:'確認訂單',
		confirm2:'配送服務',
		confirm4:'訂單詳情',
		confirm5:'提交訂單',
		confirm6:'新增新的地址',
		tip1:'提示',
		tip2:'我已知曉並同意下單',
		address1:'地址列表',
		address2:'新增地址',
		address3:'新增新的地址',
		address4:'編輯',
		address5:'刪除',
		address6:'收件人姓名',
		address7:'省市區',
		address8:'郵政編碼',
		address9:'聯系電話',
		address10:'詳細地址',
		address11:'是否爲默認',
		address12:'保存',
		support1:'售後支持',
		support2:'常見問題',
		support3:'發送郵件至',
		support4:'我們的客服從10:30-18:00（法定節假日除外）爲您提供售後相關的咨詢服務。',
		tip3:'海外商品壹經售出，概不退換。',
		tip4:'購買前請仔細確認商品信息，',
		tip5:'確認無誤後在進行購買。',
		tip6:'感謝您的理解與支持',
		order13:'配送地址',
		order14:'請填寫您的地址以便配送服務',
		order15:'選擇您的支付方式',
		order16:'確認您的訂單信息',
		order17:'不支持該地區售賣',
		paying1:'支付中...',
		paying2:'正在支付中...',
		paying3:'請不要關閉本頁面，稍等片刻',
		mine:'我的',
		noorder:'暫無訂單',
		password:'舊密碼',
		password1:'新密碼',
		password2:'請輸入舊密碼',
		password3:'請輸入新密碼',
		password4:'請再次輸入新密碼',
		password5:'舊密碼錯誤',
		pay8:'剩余支付時間',
		logout1:'是否確認退出登錄',
		cancel:'取消',
		showtips:'您可以使用手機號碼或郵箱登錄JJ20×NEIGHBORHOOD網站',
		accountDetail:'賬號詳情'
	},
	login:{
		email:'郵箱',
		email1:'請再次輸入郵箱',
		email2:"再次輸入郵箱",
		email3:"請輸入郵箱",
		email4:"請輸入有效郵箱",
		email5:"兩次郵箱輸入不壹致",
		email6:"郵箱驗證碼",
		email7:"輸入驗證碼",
		email8:"獲取驗證碼",
		email9:"確認郵箱",
		submitTips:'如果您沒有在收件箱中找到驗證碼郵件，請檢查您的垃圾箱或廣告郵件文件夾。由于郵件過濾設置，有時我們的郵件可能會被誤分類。如果仍然找不到，請嘗試重新發送驗證碼或聯系客護支持獲取幫助。',
		password:'密碼',
		password1:'請輸入密碼',
		password2:'請再次輸入密碼',
		password3:'至少包含',
		password4:'英文字母/數字/符號',
		password5:'中的兩項，',
		password6:'並且不低于8位',
		password7:'確認密碼',
		password8:'密碼安全',
		password9:'密碼安全性低',
		password10:'兩次輸入的密碼不壹致',
		account:'帳號',
		account1:'請輸入帳號',
		account2:'郵箱/手機號',
		account3:'確認帳號信息',
		account4:'請輸入有效帳號',
		account5:'帳號或密碼錯誤',
		account6:'請輸入有效號碼',
		agree:'我已閱讀並同意',
		agree1:'用護協議',
		agree2:'與',
		agree3:'隱私條款',
		agree4:'請勾選用護協議與隱私條款',
		login:'登錄',
		login1:'登錄',
		register:'沒有帳號？',
		register1:'注冊',
		register2:'去注冊',
		forget:'忘記密碼？',
		nextstep:'下壹步',
		upstep:'上壹步',
		phone:'手機號',
		phone1:'請輸入手機號',
		phone2:'手機驗證碼',
		phone3:'確認手機號',
		code:'驗證碼無效',
		code1:'驗證碼',
		code2:'請輸入驗證碼',
		code3:'請重新發送驗證碼',
		toast:'注冊成功',
		toast1:'修改成功',
		toast2:'發送成功',
		findphone:'手機找回',
		findemail:'郵箱找回',
		changepass:'修改密碼',
		findpass:'忘記密碼',
		surechange:'確定修改',
		areaCode1:'選擇區號',
	},
	shopMall:{
		shopHome: '首頁',
		startSelling: '開始預售',
		selling: '售賣中',
		willStart: '即將開始',
		sellOut: '已售罄',
		privacy: '隱私政策',
		service: '服務條款',
		cookieNotice: '為了優化您的體驗，我們使用Cookie來收集信息並個性化內容。請點擊“接受”以繼續使用我們的網站。',
		cookieReject: '拒絕',
		cookieAccept: '接受',
		introTitle: 'JJ20即將來到你的NEIGHBORHOOD！',
		introContent01: "為紀念JJ林俊傑出道20周年，JJ林俊傑與自己欣賞和支持的日本街头文化的著名品牌 NEIGHBORHOOD首度攜手合作，推出「JJ20  x NEIGHBORHOOD」聯名系列！這次合作也因為恰逢NEIGHBORHOOD 成立30週年里程碑，而別具意義。",
		introContent02: 'JJ20巡演10月即將登陸東京，正是將裏原宿文化搬上舞台的最佳時刻。',
		introContent03: '隆重介紹 JJ20 x NEIGHBORHOOD：限量版系列，旨在致敬音樂、街頭文化以及兩個標誌性週年紀念日。',
		number: '數量',
		addCart: '加入購物車',
		userNotPay: '有用護拍下未付款',
		againBuy: '稍後再來',
		noneGoods: '購物車暫時沒有商品',
		shoppingLook: '去逛逛看吧',
		goOnShoping: '繼續購物',
		showAllGoods: '展開全部失效商品',
		settleAccounts: '去結帳',
		notice: '提示',
		deleteGoodsNotice: '是否移除該商品',
		cancel: '取消',
		remove: '移除',
		removeSuccess: '移除成功',
		shopDetail: '詳情',
		shopCart: '購物車',
		goodsSellOut: '商品已售罄',
		addSuccess: '加購成功',
		buyNotice: '購買須知',
		selectText: '請選擇',
		selectSkuTips: '請選擇要購買的',
		willSell: '即將開售',
		noSkuTips: '庫存不足請稍後嘗試刷新',
		settleAccountsTips: '請選擇要結帳的商品',
		exceedLimitNum: '超出商品限購數量',
		miniNum: '商品最少購買壹件',
	},
		newadd:{
			address1:'默認地址',
			pc1:'請輸入收件人姓名',
			pc2:'請選擇省市區',
			pc3:'請輸入郵政編碼',
			pc4:'請輸入聯系電話',
			pc5:'請輸入詳細地址',
			confirm:'確認',
			delete:'是否刪除該地址',
			ordernew:'待支付',
			handlefail:'網絡信號差',
			handleHome:'返回首頁',
		},
		privacy: {
			line1: '本協議更新時間：2024年9月16日',
			line2: '本協議生效時間：2024年9月16日',
			line3: '《JJ20XNEIGHBORHOOD商城》服務（以下簡稱本服務）系由上海歐露塔科技有限公司 （以下簡稱本公司）向您提供的用戶服務。本公司非常重視您的隱私及個人信息保護，並基于法律法規及本服務的服務條款制定本隱私政策，以提供您更完善的服務內容。',
			line4: '請您務必經過充裕的時間仔細閱讀並充分了解本隱私政策的所有內容，壹旦您以任何形式或方式開始使用本服務，即表示您已同意接受本隱私政策的所有內容。',
			line5: '您的年齡如果未滿18歲，請您在使用本服務之前，務必在您的法定代理人、監護人的陪同下經過充裕的時間仔細閱讀並充分了解本隱私政策的所有內容，壹旦您以任何形式或方式開始使用本服務，即表示您已獲得法定代理人、監護人同意接受本隱私政策的所有內容。',
			line6: '1、本公司得變更本隱私政策的任壹內容，並以公告、電子郵件通知或其他合理的方式通知您，您如果不同意本隱私政策，請您立即停止使用本服務，壹旦您在前述變更後繼續使用本服務，即視爲您已經同意接受本隱私政策的變更。',
			line7: '2、本公司于運營地域、期間內，爲了營銷、推廣、執行本服務相關業務及前述相關業務的管理、調查與研究分析及改善前述相關業務的特定目的範圍內，您同意本公司得收集、處理、利用及跨境傳輸您注冊、登錄及使用本服務時以任何形式、方式所提供的下列個人信息（以下簡稱個人信息）：',
			line8: '(1) 基本信息：包含姓名、性別、生日、地址、電話及電子郵箱等。',
			line9: '(2) 身份信息：包含身份證號碼、護照號碼等相關身分證明，以及檔案頭像、身高、年齡等相關特征。',
			line10: '(3) 設備數據：包含硬件型號、設備MAC地址、操作系統類型、軟件列表及國際移動設備識別碼或運行中的進程信息等。',
			line11: '並且，本公司將在下列情形提供您的個人信息給本公司運營地域內、外的第三方或政府機關：',
			line12: '(1) 本公司因本條目的將您的個人信息共享或轉讓給本公司關系公司、關聯公司及與本公司合作的第三方的，且該等公司、第三方得因本條目的使用您的個人信息；',
			line13: '(2) 本公司因您使用本服務涉及政府機關（包括但不限于稅務機關、海關機關及司法機關等）的要求、或因您涉有犯罪嫌疑所必須配合提供的。',
			line14: '3、本公司爲了提供您更好的服務及優化您的服務體驗，在您使用本服務、本服務相關應用程序時，本公司將記錄您的設備數據，包含網絡協議地址（IP ADDRESS）及您的浏覽紀錄、COOKIES等，作爲分析喜好與習慣、統計浏覽人次及流量及按照前述分析、統計結果調整服務內容的用途。',
			line15: '4、本公司爲了提供本服務，部分功能或服務需要使用第三方的SDK（包括但不限于金流、簡訊驗證和訊息推送等），您同意這些SDK可以收集或使用您的個人信息，以配合本公司提供前述服務。本公司將適時根據使用的SDK通過合適的版面和方式向您揭示SDK的名稱和有關信息。',
			line16: '5、本公司將以合于法律法規的技術與程序保護您的個人信息安全，防止您的個人信息被竊取、竄改、毀損、滅失或泄漏，但您了解並同意，您如果透過網絡使用本服務，由于網絡可能存在各種惡意手段，即使本公司已采取前述技術、程序盡力保護您的個人信息，仍無法保證您透過網絡提供個人信息的安全性，您務必主動采取安全措施（包括但不限于定期修改帳戶密碼等）並自行負擔相關風險及責任。',
			line17: "6、您得就您的個人信息向本公司請求查詢、閱覽、或制給複制本、或補充、更正、或請求停止收集、處理或利用、或請求刪除，本公司得就查詢、閱覽或制給複制本的請求酌收必要的處理費用。如果您需要刪除您的賬戶，請通過下列步驟提交申請表單給我們，發送郵件至NBHD{'@'}orota.cn。",
			line18: '7、本公司非常重視未成年人的個人信息保護，將根據法律法規保護未成年人的個人信息。本公司如果發現在未經事前獲得法定代理人、監護人的同意下收集了未成年人的個人信息，則會設法盡快刪除相關信息。',
			line19: "8、您如果對本隱私政策有任何疑問、意見或建議，歡迎您透過本服務與我們聯系，請發送郵件至NBHD{'@'}orota.cn，壹般會在5個工作日內回複。您如果因爲本隱私政策産生任何爭議，您同意先與本公司進行友好協商，雙方如果無法達成協商而涉訟，您同意以上海市闵行區人民法院作爲管轄法院。",
			line20: '9、聯系方式',
			line21: "電子郵箱: NBHD{'@'}orota.cn",
		},
		service: {
            line1: '本條款更新時間：2024年9月16日',
            line2: '本條款生效時間：2024年9月16日',
            line3: '《JJ20XNEIGHBORHOOD商城》服務（以下簡稱本服務）系由上海歐露塔科技有限公司（以下簡稱本公司）向您提供的用戶服務。本服務條款是您與本公司之間就注冊本服務賬戶和使用本服務各項服務相關事宜所訂立的協議。',
            line4: '您在使用本服務之前，請務必經過充裕的時間仔細閱讀並充分了解本服務條款的所有內容，壹旦您以任何形式或方式開始使用本服務，即表示您已同意接受本服務條款的所有內容。',
            line5: '您的年齡如果未滿18歲，請您在使用本服務之前，務必在您的法定代理人、監護人的陪同下經過充裕的時間仔細閱讀並充分了解本服務條款的所有內容，壹旦您以任何形式或方式開始使用本服務，即表示您已獲得法定代理人、監護人同意接受本服務條款的所有內容。',
            line6: '1、前言',
            line7: '1.1 本公司得將本服務的部分或全部移轉、讓與給本公司的關系公司、關聯公司或與本公司合作的第三方，由其進行運營、管理及履行。',
            line8: '1.2 本公司得變更或終止本服務條款的任壹內容，並以公告通知或其他合理的方式通知您，但前述變更、終止不以您收到通知爲必要，您如果在前述變更後繼續使用本服務，即視爲您已經同意接受本服務條款的變更、終止。',
            line9: '2、服務內容',
            line10: '2.1 本服務的具體內容由本公司根據實際情況提供，包括但不限于活動預約等，本公司並得隨時對本服務進行更新、調整及維護，包括但不限于本服務的功能升級、強化、限制及加入新服務等，您了解並同意，本公司得不向您特別通知該等更新、調整及維護。',
            line11: '2.2 鑒于網絡服務的特殊性（包括但不限于服務器的穩定性、惡意網絡攻擊及其他本公司無法控制的情形），本公司得隨時中斷或終止本服務的部分或全部服務；如有前述情形，本公司將以公告通知或其他合理的方式通知您。',
            line12: '2.3 妳了解本公司透過網絡提供本服務，您使用本服務所需要的相關設備（包含手機、計算機及平板等設備），以及您連接網絡所需要的費用（包含電話費、上網費等），均由您自行承擔。',
            line13: '2.4 商品和訂單：',
            line14: '(1) 您在下訂單前，請您仔細確認所購商品的名稱、價格、數量、型號、規格、尺寸、聯系地址、電話、收貨人等信息。收貨人與您本人不壹致的，收貨人的行爲和意思表示將視爲您的行爲和意思表示，並且您應對收貨人的行爲與意思表示承擔連帶責任。',
            line15: '(2) 售賣頁面僅爲商品的展示，商品的實際提供者收到您的訂單後，將根據商品是否缺貨等情形接受您的訂單，壹旦商品的實際提供者接受了您的訂單（提醒！下訂單時因購買手續所自動發出的通知、驗證訊息，不必然代表訂單已經被接受），即表示您就所購商品與其成立購買合同的關系，由商品的實際提供者依照訂單發貨給您。',
            line16: '(3) 商品的實際提供者基于市場變化與各種合理商業努力仍無法避免的影響，其接受了訂單之後假若發生缺貨或其他無法出貨的情形，商品的實際提供者有權與您協商取消訂單、購買合同，若您已經付款的，則將爲您辦理退款。',
            line17: '3、賬戶規範',
            line18: '3.1 您使用本服務所注冊的賬戶（以下簡稱賬戶），其名稱、密碼是您使用本服務的憑證，您不得任意向他人透漏賬戶名稱及密碼，並應適時更新密碼及采取相關保護措施，以妥善維護該賬戶的安全性及保密性。您了解並同意，任何以該賬戶所從事的活動均由您承擔所有責任，您如果懷疑、發現他人擅自使用該賬戶或有其他安全問題，請立即通知本公司。',
            line19: '3.2 您應使用真實身分的信息注冊賬戶，不得捏造或假冒他人的身分信息、公司信息，包括但不限于冒用名稱、頭像或簡介等，並請您按照最新的身分信息異動賬戶數據。您如果有任何捏造、冒用的情形或本公司有合理的理由懷疑賬戶數據有任何錯誤、不實或不合法，本公司得拒絕您使用本服務或限制您使用本服務的部分功能，您同意不作任何異議、主張。',
            line20: '3.3 您了解賬戶的所有權及相關權益均歸本公司所有，您完成注冊手續後僅享有該賬戶的使用權，且該使用權屬于賬戶的初始注冊人。您如果以贈與、借用、出租、轉讓、販賣等任何形式或方式提供賬戶予他人使用，經本公司發現或本公司有任何合理理由認爲賬戶的使用者非初始注冊人，本公司得直接暫停、終止及注銷賬戶，毋須事前通知您。',
            line21: '3.4 您如果遺忘賬戶的名稱、密碼，您可以透過本公司公布的手續申請找回賬戶的名稱或密碼。您了解前述手續僅需要識別申請者所提供的數據與本服務紀錄的壹致性，無法識別申請者是否是賬戶的真正使用權人，您不得以前述手續主張本公司已同意申請者爲賬戶的使用權人。',
            line22: '4、行爲規範',
            line23: '4.1 您應充分了解並同意，您須爲自己賬戶下的壹切行爲負責，包括但不限于違法違規、違反本服務條款和該擾交易秩序等，以及由此産生的任何後果。',
            line24: '4.2 您使用本服務不得有下列行爲：',
            line25: '(1) 冒充、利用他人名義；',
            line26: '(2) 通過不正當手段達到交易或參與活動的目的，例如使用機器人軟件、模擬器等工具；',
            line27: '(3) 利用技術漏洞或服務條款漏洞進行下單、獲取福利和補貼；',
            line28: '(4) 惡意退貨、換貨或藉此獲得不正當利益；',
            line29: '(5) 非正常的多次下單、退單影響商品流轉等幹擾交易秩序；',
            line30: '(6) 違反善良風俗、法律法規或侵犯他人權利；',
            line31: '(7) 其他本公司認爲影響他人權益或不適于本服務的行爲。',
            line32: '4.3、您使用本服務有關程序的過程，不得有下列行爲：',
            line33: '(1) 頻繁、大量注冊賬戶或以其他方式幹擾程序；',
            line34: '(2) 反向工程、反向彙編、反向編譯或以其他方式嘗試發現程序原始碼；',
            line35: '(3) 使用插件、外挂或任何第三方工具/服務複制、修改、增加、刪除、挂接程序數據、數據或作成任何衍生作品；',
            line36: '(4) 其他未經本公司允許的行爲。',
            line37: '4.4、您使用本服務時如果涉及第三方提供的服務，您除了遵守本服務條款之外，亦須遵守該服務的相關規範、規則，您如果因爲該服務産生任何爭議、損失等，由您自行與該第三方解決。',
            line38: '4.5、您如果有任何違反行爲規範的情形或本公司有合理理由懷疑您的行爲有任何違反行爲規範的可能，本公司得拒絕您使用本服務或暫停、終止、注銷您的賬戶，並向您追究相關責任。',
            line39: '5、未成年人條款',
            line40: '5.1 您如果未滿18歲，您應該法定代理人、監護人的指導下使用本服務，並遵守下列規範：',
            line41: '(1) 要善于網上學習，不浏覽不良信息；',
            line42: '(2) 要誠實友好交流，不侮辱欺詐他人；',
            line43: '(3) 要增強自護意識，不隨意約會網友；',
            line44: '(4) 要維護網絡安全，不破壞網絡秩序；',
            line45: '(5) 要有益身心健康，不沈溺虛擬時空。',
            line46: '5.2 本公司非常注重未成年人的保護，未成年人如欲使用本服務進行任何消費行爲，請您務必在法定代理人、監護人的陪同及明示同意下進行。',
            line47: '6、隱私政策',
            line48: '6.1 本公司注重您的個人信息及個人隱私，將運用與本服務相匹配的安全技術及其他安全措施保護您的個人信息。',
            line49: '6.2 請您務必經過充裕的時間仔細閱讀並充分了解本公司《JJ20XNEIGHBORHOOD商城》隱私政策的所有內容，您如果不同意該等內容，可能導致本服務無法正常運作、或使相關功能受到限制；壹旦您以任何形式或方式開始使用本服務，即表示您已同意接受該隱私政策的所有內容。',
            line50: '7、廣告',
            line51: '7.1 您了解本服務內容可能包括本公司針對個人或企業的廣告服務，您同意本公司得在本服務中展示本服務合作夥伴、贊助商、供貨商的商業廣告、推廣或信息（包括商業或非商業信息）。',
            line52: '7.2 本公司將依照法律法規展示廣告，您應自行判斷該廣告、推廣及信息的真實性與可靠性，並應爲自己的判斷負責。除了法律法規另有明確規定外，您因該廣告、推廣及信息所進行的購買、交易行爲，或因該等購買、交易行爲遭受的損害或損失，均由您自行承擔。',
            line53: '8、知識産權',
            line54: '8.1 本公司對本服務及相關應用程序提供的所有內容以及其開發、運營等過程中産生的所有程序代碼和信息等享有全部權利，非經本公司事前書面同意，您不得以任何方式、形式（包括但不限于通過任何程序或設備複制、展示、傳播、監視、鏡像、上載、下載）使用前述內容、程序代碼及信息。',
            line55: '8.2 您不得將本公司的任何商標、服務標記、商號、域名、網站名稱或其他顯著品牌特征等（以下統稱爲標識）以單獨或結合任何方式展示、使用或申請注冊商標、進行域名注冊等，也不得實施向他人明示或暗示有權展示、使用、或其他有權處理該些標識的行爲。您如果因此造成本公司或任何第三方的損失，由您承擔全部責任。',
            line56: '9、違約責任',
            line57: '9.1 針對您違反本服務條款的行爲，本公司得獨立判斷並不經通知隨時對相關內容進行刪除、屏蔽，視情節對違規賬戶進行限制部分或全部功能、帳戶封禁、注銷等措施，並公告處理結果。對涉嫌違反法律法規的行爲，本公司將保存有關記錄，並依法配合有關主管部門調查。您應自行承擔由此産生的壹切法律責任。',
            line58: '9.2 您應了解並同意，因您違反本服務條款的規定，導致或産生第三方主張的任何索賠、要求或損失，您應當獨立承擔責任；本公司及其關系公司、關聯公司及本公司合作公司如果因爲您的行爲遭受任何損失（包括但不限于經濟、商譽等損失等），您亦應承擔所有賠償責任。',
            line59: '10、免責聲明',
            line60: '10.1 您在使用本服務的過程中應遵守法律法規及政策規定，若因違反産生的行爲後果由您自行承擔。',
            line61: '10.2 鑒于外部鏈接指向的內容非由本公司實際控制，本公司無法保證設置的外部鏈接的准確性和完整性。',
            line62: '10.3 對于因不可抗力造成的本服務或相關應用程序中斷或其它缺陷，本公司不承擔任何責任。',
            line63: '10.4 本服務是本公司按照現有技術和條件所能達到的現狀提供，本公司會盡最大努力確保服務的連貫性和安全性。您了解並同意，本公司不能隨時預見和防範法律、技術以及其他風險，包括但不限于木馬、病毒、黑客攻擊、系統不穩定、第三方服務瑕疵、不可抗力及其他各種安全問題可能導致的服務中斷、數據丟失以及其他的損失和風險。本公司得爲服務運營的需要，采取合適的方式通知後修改、中斷、中止或終止本服務，毋須承擔任何賠償責任。',
            line64: '11、其他',
            line65: '11.1 本服務條款以中華人民共和國法律爲准據法。您如果因爲本服務及本服務條款産生任何爭議，您同意先與本公司進行友好協商，雙方如果無法達成協商而涉訟，您同意以上海市闵行區人民法院作爲管轄法院。',
            line66: '11.2 本服務條款的標題僅爲閱讀方便，本身並無實際涵義，不能作爲本服務條款涵義解釋的依據。',
            line67: '11.3 本服務條款如果因爲任何原因完全或部分無效或不具有執行力，本服務條款的其余條款仍應有效並且有約束力。',
            line68: '12、聯系方式',
            line69: "電子郵箱: NBHD{'@'}orota.cn",
        }
};
