<template>
	<van-popup v-model:show="show" :close-on-click-overlay="false">
		<div class="popOver">
			<div class="popM">
				<div class="commonFont bold">{{ $t("message.mine.pay2") }}</div>
				<div class="greyfont mg10 font12">{{ $t("message.mine.pay5") }}</div>
				<div
					v-for="item in options"
					:key="item.value"
					:class="paymethod == item.value ? 'lanItemA' : 'lanItem'"
					@click="paymethod = item.value"
				>
					<img
						v-if="item.value == 'global_alipay'"
						src="../../assets/images/common/alipay.png"
						alt=""
					/>
					<img v-if="item.value == 'paypal'" src="../../assets/images/common/paypal.png" alt="" />
				</div>
			</div>
			<div class="greyLine" style="margin-top: 20px; margin-bottom: 0px"></div>
			<div class="popBtn commonFont bold" @click="choose(paymethod)">
				{{ $t("message.mine.pay4") }}
			</div>
			<div class="outer" @click="hidePay">
				<img src="../../assets/images/neighborhood/pop_close_icon.png" alt="" />
			</div>
		</div>
	</van-popup>
</template>

<script setup>
import { onMounted, reactive, ref, getCurrentInstance, defineProps, defineExpose } from "vue";
const props = defineProps(["choose", "pay"]);
const show = ref(false);
const paymethod = ref(undefined);
const { proxy } = getCurrentInstance();
const { locale, t } = proxy.$useI18n();
const options = reactive([
	{
		label: "Alipay Global",
		value: "global_alipay",
	},
	{
		label: "Paypal",
		value: "paypal",
	},
]);
const showPay = (e) => {
	paymethod.value = e.payMethod;
	show.value = true;
};
const hidePay = () => {
	show.value = false;
};
defineExpose({
	showPay,
	hidePay,
});
</script>

<style lang="less" scoped>
.lanItem {
	opacity: 0.5;
	margin-top: 15px;
	img {
		width: 315px;
		height: 52px;
	}
}
.lanItemA {
	opacity: 1;
	margin-top: 15px;
	img {
		width: 315px;
		height: 52px;
	}
}
.van-popup {
	background: rgba(0, 0, 0, 0) !important;
	overflow: initial !important;
}
.popOver {
	padding: 20px 0px 0px 0px;
	background: #1d1d1d;
	text-align: center;
	position: relative;
	&::after {
		z-index: 1;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 0.5px solid rgba(153, 153, 153, 1);
	}
	.popM {
		padding: 0px 15px;
		position: relative;
		z-index: 2;
	}
	.popBtn {
		position: relative;
		z-index: 2;
		padding: 16px 0px;
	}
}
.outer {
	position: absolute;
	bottom: -45px;
	left: calc(50% - 12.5px);
	z-index: 3;
	img {
		width: 25px;
		height: 25px;
	}
}
.greyLine {
	height: 0.5px;
	background: rgba(153, 153, 153, 1);
	margin: 15px 0px;
}
</style>
