<template>
	<div style="width: 100%; max-width: 500px; position: relative; overflow: hidden">
		<router-view />
	</div>
	<div class="page_tips">
		<p>横屏体验效果不佳</p>
		<p>请竖屏，并关闭手机旋转</p>
	</div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const typeIsMobile = ref(false);
onMounted(() => {
	typeIsMobile.value = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	if (typeIsMobile.value) {
		window.addEventListener(
			"onorientationchange" in window ? "orientationchange" : "resize",
			screenFn,
			false
		);
	}
});
const screenFn = () => {
	const scrollHeight = document.documentElement.clientHeight || document.body.clientHeight;

	if (window.orientation === 90 || window.orientation === -90) {
		// 横屏

		document.getElementsByClassName("page_tips")[0].style.height = scrollHeight + "px";
		document.getElementsByClassName("page_tips")[0].style.display = "block";
	} else {
		// 竖屏

		document.getElementsByClassName("page_tips")[0].style.display = "none";
	}
};
// 路由监听横竖屏
watch(
	() => router.currentRoute.value,
	(newValue) => {
		if (typeIsMobile.value) {
			// console.log("？？？", typeIsMobile.value);
			const _currentWidth = document.documentElement.clientWidth || document.body.clientWidth;
			const _currentHeight = document.documentElement.clientHeight || document.body.clientHeight;

			if (_currentWidth > _currentHeight) {
				// 横屏

				document.getElementsByClassName("page_tips")[0].style.height = _currentHeight + "px";
				document.getElementsByClassName("page_tips")[0].style.display = "block";
			} else {
				// 竖屏

				document.getElementsByClassName("page_tips")[0].style.display = "none";
			}
		}
	},
	{ immediate: true }
);
const ThemeColor = () => {
	document.body.style.setProperty("--el-color-primary", "#dbce5c"); //主色
	// document.body.style.setProperty("--el-color-success", "#1dc779"); //成功色
	// document.body.style.setProperty("--el-color-waring", "#ffb302"); //警告色
	// document.body.style.setProperty("--el-color-danger", "#e26237"); //危险色
	// document.body.style.setProperty("--el-color-danger", "#cf4444"); //错误色
};
ThemeColor();
</script>
<style lang="less">
@import "@/assets/app.less";
#app {
	/*font-family: Avenir, Helvetica, Arial, sans-serif;*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	display: flex;
	justify-content: center;
}
html,
body,
#app {
	/*color: #606266;*/
	width: 100%;
	height: 100%;
	font-family: "Microsoft YaHei", "Avenir", Helvetica, Arial, sans-serif;
	overflow: hidden;
	background: #000000;
	cursor: default;
	position: fixed;
	bottom: 0;
	&::-webkit-scrollbar {
		display: none;
	}
}
.el-cascader-panel {
	max-width: 300px;
	overflow: auto;
}
.page_tips {
	width: 100%;
	height: 100%;
	background: #ababab;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	z-index: 3000;

	p {
		font-size: 16px;
		color: #ffffff;
		margin-top: 5px;
		text-align: center;
		font-family: "Avenir";

		&:first-child {
			margin-top: 120px;
		}
	}
}
</style>
