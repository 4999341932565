<template>
	<van-popup v-model:show="show" :close-on-click-overlay="false">
		<div class="popOver">
			<div class="popM">
				<div class="commonFont bold">{{ $t("message.mine.pay6") }}</div>
				<div class="greyfont mg10 font12">{{ word }}</div>
				<div class="commonFont font12 mg3 flexCenter copybtn" @click="copy">
					<div>NBHD@orota.cn</div>
					<img src="../../assets/images/common/copy.png" alt="" />
				</div>
				<div class="greyfont mg10 font12">{{ $t("message.mine.after1") }}</div>
			</div>
			<div class="greyLine" style="margin-top: 20px; margin-bottom: 0px"></div>
			<div class="popBtn commonFont bold" @click="hidePay">
				{{ $t("message.mine.log4") }}
			</div>
			<div class="outer" @click="hidePay">
				<img src="../../assets/images/neighborhood/pop_close_icon.png" alt="" />
			</div>
		</div>
	</van-popup>
	<showtoast :text="$t('message.mine.copy')" :loading="loading" />
</template>

<script setup>
import { onMounted, reactive, ref, getCurrentInstance, defineProps, defineExpose } from "vue";
const props = defineProps(["word"]);
const show = ref(false);
const paymethod = ref(undefined);
const { proxy } = getCurrentInstance();
const { locale, t } = proxy.$useI18n();
const loading = ref(false);
const showPay = () => {
	show.value = true;
};
const hidePay = () => {
	show.value = false;
};
const copy = () => {
	const textArea = document.createElement("textarea");
	textArea.value = "NBHD@orota.cn";
	// 将 textarea 元素添加到 DOM 中
	document.body.appendChild(textArea);
	// 选中文本
	textArea.select();
	// 执行复制命令
	document.execCommand("copy");
	// 移除 textarea 元素
	document.body.removeChild(textArea);
	const a = t("message.mine.copy");
	// proxy.$toast(a);
	loading.value = true;
	setTimeout(() => {
		loading.value = false;
	}, 1000);
};
defineExpose({
	showPay,
});
</script>

<style lang="less" scoped>
.van-popup {
	background: rgba(0, 0, 0, 0) !important;
	overflow: initial !important;
}
.popOver {
	padding: 20px 0px 0px 0px;
	background: #1d1d1d;
	text-align: center;
	position: relative;
	&::after {
		z-index: 1;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 0.5px solid rgba(153, 153, 153, 1);
	}
	.popM {
		padding: 0px 41px;
		box-sizing: content-box;
		width: 215px;
		position: relative;
		z-index: 2;
	}
	.copybtn {
		img {
			width: 9px;
			height: 11px;
			margin-left: 5px;
		}
	}
	.popBtn {
		position: relative;
		z-index: 2;
		padding: 16px 0px;
	}
}
.outer {
	position: absolute;
	bottom: -45px;
	left: calc(50% - 12.5px);
	z-index: 3;
	img {
		width: 25px;
		height: 25px;
	}
}
.greyLine {
	height: 0.5px;
	background: rgba(153, 153, 153, 1);
	margin: 15px 0px;
}
</style>
