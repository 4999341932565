import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn';
import TopHeader from './components/common/TopHeader.vue';
import PayMethod from './components/common/payMethod.vue';
import AfterSale from './components/common/afterSale.vue';
import Logistics from './components/common/logistics.vue';
import Huakuai from './components/common/huakuai.vue';
import showtoast from './components/common/alertModal.vue';
import NoticeDialog from './components/common/noticeDialog.vue';
import { Dialog, showConfirmDialog, showToast, List, PullRefresh, Popup, DatePicker, Calendar, Radio, RadioGroup, Skeleton, Tab, Tabs, Swipe, SwipeItem, Circle, Switch, Collapse, CollapseItem } from 'vant';
import 'vant/lib/index.css';
import dayjs from 'dayjs';
import { limit } from './components/LimitClick';
import { createI18n, useI18n } from 'vue-i18n';
import { post, get } from './utils/request';
import { Encrypt, Decrypt } from './utils/secret';
import { getCurrency } from './utils/currency';
import mitt from 'mitt'
import registerDirectives from './directives' // 自定义指令
const app = createApp(App);
let locationFlag = location.host.split('.').pop();
const _thisLanguage = localStorage.getItem('language') || (locationFlag == 'com' ? 'EN' : 'CN');
// localStorage.setItem('language', _thisLanguage);
const i18n = createI18n({
  locale: _thisLanguage,
  legacy: false,
  messages: {
    CN: require('./assets/language/cn'),
    EN: require('./assets/language/en'),
    TW: require('./assets/language/tw'),
    JPN: require('./assets/language/ja'),
  }
});

// 全局方法
app.config.globalProperties.$showDialog = showConfirmDialog;
app.config.globalProperties.$toast = showToast;
app.config.globalProperties.$date = dayjs;
app.config.globalProperties.$limit = limit;
app.config.globalProperties.$useI18n = useI18n;
app.config.globalProperties.$post = post;
app.config.globalProperties.$get = get;
app.config.globalProperties.$encrypt = Encrypt;
app.config.globalProperties.$decrypt = Decrypt;
app.config.globalProperties.$getCurrency = getCurrency;
app.config.globalProperties.$mitt = mitt();

app.component("TopHeader", TopHeader);
app.component("PayMethod", PayMethod)
app.component("Logistics", Logistics)
app.component("AfterSale", AfterSale)
app.component("Huakuai", Huakuai)
app.component("showtoast", showtoast)
app.component("NoticeDialog", NoticeDialog);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
registerDirectives(app)
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.use(Dialog);
app.use(List);
app.use(PullRefresh);
app.use(Popup);
app.use(DatePicker);
app.use(Calendar);
app.use(Radio);
app.use(RadioGroup);
app.use(i18n);
app.use(Skeleton);
app.use(Tab);
app.use(Tabs);
app.use(Swipe);
app.use(SwipeItem);
app.use(Circle);
app.use(Switch);
app.use(Collapse);
app.use(CollapseItem);
app.mount("#app");
