<template>
	<div>
		<div id="captcha-element"></div>
		<div id="show_huakuai"></div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref, getCurrentInstance, defineProps, defineExpose, onBeforeUnmount } from "vue";
import { get, post } from "../../utils/request";
const { proxy } = getCurrentInstance();
const { locale, t } = proxy.$useI18n();
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const props = defineProps(["functionSuccess"]);
const setLanguage = ref("cn");
onMounted(() => {
	if (locale.value === "JPN") {
		setLanguage.value = "ja";
	} else {
		setLanguage.value = locale.value.toLowerCase();
	}
	showHuaKuai();
});
onBeforeUnmount(() => {
	document.getElementById('aliyunCaptcha-mask')?.remove();
    document.getElementById('aliyunCaptcha-window-popup')?.remove();
});
const showHuaKuai = () => {
	console.log("showHuakuai");
	let captcha;
	// 弹出式
	initAliyunCaptcha({
		SceneId: "1b7b13sj", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
		prefix: "1op33w", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
		mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
		element: "#captcha-element", //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
		button: "#show_huakuai", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
		captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
		onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
		getInstance: getInstance, // 绑定验证码实例函数，无需修改
		slideStyle: {
			width: 360,
			height: 40,
		}, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
		language: setLanguage.value, // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
		region: "cn", //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
	});

	// 绑定验证码实例函数。该函数为固定写法，无需修改
	function getInstance(instance) {
		captcha = instance;
		document.getElementById("aliyunCaptcha-mask").onclick = function (e) {
			e.preventDefault();
			console.log("拼图滑块验证");
		};
	}

	async function captchaVerifyCallback(captchaVerifyParam) {
		// 1.向后端发起业务请求，获取验证码验证结果和业务结果
		props.functionSuccess(captchaVerifyParam);
		captcha.hide();
	}

	// 业务请求验证结果回调函数
	function onBizResultCallback(bizResult) {
		if (bizResult === true) {
			// 如果业务验证通过，跳转到对应页面。此处以跳转到https://www.aliyun.com/页面为例
			// window.location.href = 'https://www.aliyun.com/';
		} else {
			// 如果业务验证不通过，给出不通过提示。此处不通过提示为业务验证不通过！
			// alert('业务验证不通过！');
		}
	}
};
defineExpose({
	showHuaKuai,
});
</script>
