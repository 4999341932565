<template>
	<div style="width: 100%; height: 100%">
		<div
			class="topContainer spaceBetween"
			:class="bk == 'back' ? 'topbg' : bk == 'black' ? 'topblack' : ''"
		>
			<div class="left">
				<div>{{ title }}</div>
			</div>
			<div class="right flexCenter">
				<img class="language" @click="goLanguage" src="../../assets/images/common/language.png" />
				<div class="flexStart cartout" @click="goCart">
					<img class="cart" src="../../assets/images/common/cart.png" v-if="!cartNumber" />
					<img class="cart" src="../../assets/images/common/white_cart.png" v-else />
					<div class="numberout flexCenter" v-if="cartNumber">
						<div class="number">{{ cartNumber }}</div>
					</div>
				</div>
				<img
					class="icon"
					@click="navigateOut"
					src="../../assets/images/common/navigator.png"
					alt=""
				/>
			</div>
		</div>
		<div class="navigator" :class="{ 'slide-in': isOpen }">
			<div class="inner">
				<div class="main">
					{{ $t("message.mine.mine") }}
				</div>
				<div class="commonFont" style="margin-top: 16px; padding-left: 15px; margin-bottom: 5px">
					Hi,{{ phone }}
				</div>
				<div
					v-for="(item, index) in mineList"
					:key="index"
					class="item commonFont"
					@click="go(item)"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="nvbottom" @click="goHome">
				{{ $t("message.newadd.handleHome") }}
			</div>
		</div>
		<div class="overlay" v-if="isOpen" @click="close"></div>
		<NoticeDialog
			v-if="showDeleteDialog"
			:title="$t('message.mine.tip1')"
			:content="$t('message.mine.logout1')"
			:cancelText="$t('message.mine.cancel')"
			:cancel="
				() => {
					showDeleteDialog = false;
				}
			"
			:confirmText="$t('message.mine.log4')"
			:confirm="logout"
		/>
	</div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { onMounted, reactive, ref, getCurrentInstance, defineProps, onBeforeUnmount } from "vue";
import { get, post } from "../../utils/request";
const router = useRouter();
const props = defineProps(["title", "background"]);
const { proxy } = getCurrentInstance();
const { locale, t } = proxy.$useI18n();
const isOpen = ref(false);
const showDeleteDialog = ref(false);
const bk = ref();
const mineList = reactive([
	{
		name: t("message.mine.mine1"),
		url: "/mineOrder",
	},
	{
		name: t("message.mine.mine2"),
		url: "/account",
	},
	{
		name: t("message.mine.mine3"),
		url: "/address",
	},
	{
		name: t("message.mine.mine4"),
		url: "/support",
	},
	{
		name: t("message.mine.mine5"),
		url: "logout",
	},
]);
const cartNumber = ref();
const phone = ref();
const goLanguage = () => {
	router.push("/language");
};
const goCart = () => {
	let storage = localStorage.getItem("access_token");
	if (!storage) {
		router.push("/login");
	} else {
		router.push("/shopCart");
	}
};
const navigateOut = () => {
	let storage = localStorage.getItem("access_token");
	if (!storage) {
		router.push("/login");
	} else {
		isOpen.value = true;
	}
};
const goHome = () => {
	router.push("/shopHome");
};
const close = () => {
	isOpen.value = false;
};
const go = (item) => {
	if (item.url == "logout") {
		showDeleteDialog.value = true;
		return;
	}
	isOpen.value = false;
	router.push(item.url);
};
const getNumber = () => {
	let storage = localStorage.getItem("access_token");
	if (storage) {
		get("/api-nbhd/shop/cart/count", {}, false, false).then((res) => {
			if (res.code == 1) {
				cartNumber.value = res.result.count;
			}
		});
	}
};
const logout = () => {
	post("/api-nbhd/user/logout").then((res) => {
		if (res.code == 1) {
			router.push("/login");
			showDeleteDialog.value = false;
			localStorage.removeItem("access_token");
			router.replace("/login");
		}
	});
};
onMounted(() => {
	bk.value = props.background;
	phone.value = localStorage.getItem("phone");
	getNumber();
	proxy.$mitt.on("updateCart", () => {
		getNumber();
	});
	if (window.location.href.includes("shopHome")) {
		const scrollView = document.querySelector(".routerView");
		const handleScroll = () => {
			if (scrollView.scrollTop > 200) {
				bk.value = "black";
			} else {
				bk.value = "";
			}
		};
		scrollView.addEventListener("scroll", handleScroll);
		onBeforeUnmount(() => {
			scrollView.removeEventListener("scroll", handleScroll);
		});
	}
});
onBeforeUnmount(() => {
	proxy.$mitt.off("updateCart");
});
</script>

<style lang="less" scoped>
* {
	box-sizing: border-box;
	color: #ffffff;
}
.topContainer {
	width: 100%;
	padding: 0px 15px 0px 15px;
	position: fixed;
	max-width: 500px;
	z-index: 10;
	height: 66px;
	top: 0;
	overflow: hidden;
}
.topbg {
	background-image: url("../../assets/images/neighborhood/detail_header_back.jpg");
	background-repeat: no-repeat;
	background-size: 100%;
}
.topblack {
	background: #000000;
}
.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
.spaceBetween {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.line {
	width: 1px;
	height: 20px;
	margin: 0px 12px;
	background: #ffffff;
	opacity: 0.5;
}
.left {
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 800;
}
.right {
	.cartout {
		margin-left: 18px;
		margin-right: 15px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
	}
	.cart {
		width: 20.5px;
		height: 16px;
	}
	.language {
		width: 18px;
		height: 18px;
	}
	.numberout {
		background: #895cdb;
		color: #ffffff;
		z-index: 2;
		position: absolute;
		top: -2px;
		right: -2px;
		border-radius: 50%;
		text-align: center;
		width: 13px;
		height: 13px;
	}
	.number {
		font-size: 10px;
		line-height: 13px;
	}
	.icon {
		width: 22px;
		height: 12px;
		img {
			width: 22px;
			height: 12px;
		}
	}
	.rednote {
		width: 20px;
		height: 20px;
		position: absolute;
		top: -6px;
		right: -6px;
		background: black;
		color: #ffffff;
		font-size: 12px;
		line-height: 10px;
		text-align: center;
		padding: 5px;
		border-radius: 20px;
	}
}
.navigator {
	height: 100%;
	width: 245px;
	left: -245px;
	transition: left 0.4s ease;
	position: absolute;
	z-index: 11;
	padding: 15px 0px;
	background: rgba(29, 29, 29, 1);
	.inner {
		.main {
			font-weight: 800;
			font-size: 24px;
			color: #ffffff;
			padding-left: 15px;
		}
		.item {
			margin: 50px 0px 0px 25px;
		}
		.greyLine {
			height: 0.5px;
			background: rgba(102, 102, 102, 1);
			width: 100%;
			margin-top: 9px;
			margin-bottom: -4px;
		}
	}
	.nvbottom {
		position: absolute;
		bottom: 31px;
		left: 25px;
		font-weight: 500;
		font-size: 14px;
		color: #999999;
	}
}
.overlay {
	width: 100%;
	height: 100%;
	z-index: 10;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
}
.slide-in {
	left: 0; /* 滑动到视图内 */
}
.closeIcon {
	position: absolute;
	right: 10px;
}
</style>
