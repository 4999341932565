export const message = {
	language: "JA",
	mine: {
		mine1: "注文履歴",
		mine2: "アカウント設定",
		mine3: "住所管理",
		mine4: "カスタマーサポート",
		mine5: "ログアウト",
		order1: "全注文",
		order2: "支払い待ち",
		order3: "発送待ち",
		order4: "完了",
		order11: "受取待ち",
		order12: "閉じられた",
		finished: "もうありません",
		loading: "読み込み中...",
		nodata: "関連する注文はありません",
		order5: "注文番号",
		order6: "スタイル",
		order7: "数量",
		order8: "合計",
		order9: "件",
		order10: "総額",
		pay1: "5分以内に注文が自動キャンセルされます",
		pay2: "支払い方法を変更",
		pay3: "今すぐ支払う",
		pay4: "支払いを確認",
		pay5: "支払い方法を選択してください",
		pay6: "アフターサポート",
		pay7: "配送状況を確認",
		log1: "配送状況は配送業者の公式サイトで追跡番号を入力して確認できます",
		log2: "配送業者",
		log3: "追跡番号",
		log4: "確認",
		after1: "オンライン時間：10:30 - 18:00（祝日除く）",
		copy: "コピー成功",
		detail1: "支払いを続けてください。5分以内に支払わないと自動キャンセルされます",
		detail2: "注文詳細",
		detail3: "商品合計",
		detail4: "送料",
		detail5: "支払い手数料",
		detail6: "注文番号",
		detail7: "情報概要",
		detail8: "状態",
		detail9: "支払い方法",
		detail10: "配送先",
		detail11: "注文日",
		confirm1: "注文確認",
		confirm2: "配送サービス",
		confirm4: "注文詳細",
		confirm5: "注文を提出",
		confirm6: "新しい住所を追加",
		tip1: "ヒント",
		tip2: "注文を確認し同意します",
		address1: "住所一覧",
		address2: "新しい住所を追加",
		address3: "新しい住所の追加",
		address4: "編集",
		address5: "削除",
		address6: "受取人名",
		address7: "都道府県市区町村",
		address8: "郵便番号",
		address9: "電話番号",
		address10: "詳細住所",
		address11: "デフォルトにする",
		address12: "保存",
		support1: "アフターサポート",
		support2: "よくある質問",
		support3: "メール送信先",
		support4: "カスタマーサポートは10:30-18:00（祝日除く）に対応します。",
		tip3: "海外商品は返品・交換できません。",
		tip4: "購入前に商品情報をご確認ください。",
		tip5: "確認後に購入してください。",
		tip6: "ご理解とご協力ありがとうございます",
		order13: "配送先住所",
		order14: "配送のために住所をご記入ください",
		order15: "支払い方法を選択してください",
		order16: "注文情報を確認してください",
		order17: "この地域での販売は対応していません",
		paying1: "支払い中...",
		paying2: "支払い中...",
		paying3: "このページを閉じないでください。少々お待ちください",
		mine: "マイページ",
		noorder: "注文がありません",
		password: "旧パスワード",
		password1: "新パスワード",
		password2: "旧パスワードを入力してください",
		password3: "新パスワードを入力してください",
		password4: "新パスワードを再入力してください",
		password5: "旧パスワードが間違っています",
		pay8: "残り支払い時間",
		logout1: "ログアウトしてもよろしいですか",
		cancel: "キャンセル",
		showtips: "JJ20×NEIGHBORHOODサイトには電話番号またはメールアドレスでログインできます",
		accountDetail: "アカウント詳細",
	},
	login: {
		email: "メールアドレス",
		email1: "メールアドレスを再入力してください",
		email2: "メールアドレス再入力",
		email3: "メールアドレスを入力してください",
		email4: "有効なメールアドレスを入力してください",
		email5: "メールアドレスが一致しません",
		email6: "メール認証コード",
		email7: "認証コードを入力",
		email8: "認証コードを取得",
		email9: "メールアドレス確認",
		submitTips:
			"認証コードのメールが受信トレイに見当たらない場合は、迷惑メールや広告メールフォルダを確認してください。メールフィルタ設定により、メールが誤分類されることがあります。それでも見つからない場合は、認証コードを再送信するか、カスタマーサポートに連絡してください。",
		password: "パスワード",
		password1: "パスワードを入力してください",
		password2: "パスワードを再入力してください",
		password3: "次のいずれかを含む必要があります",
		password4: "英字/数字/記号",
		password5: "2つ以上",
		password6: "かつ8文字以上",
		password7: "パスワード確認",
		password8: "パスワードの安全性",
		password9: "パスワードの安全性が低い",
		password10: "2回入力したパスワードが一致しません",
		account: "アカウント",
		account1: "アカウントを入力してください",
		account2: "メール/電話番号",
		account3: "アカウント情報確認",
		account4: "有効なアカウントを入力してください",
		account5: "アカウントまたはパスワードが間違っています",
		account6: "有効な番号を入力してください",
		agree: "利用規約を読み、同意します",
		agree1: "利用規約",
		agree2: "と",
		agree3: "プライバシー条項",
		agree4: "利用規約とプライバシー条項に同意してください",
		login: "ログイン",
		register: "アカウントがありませんか？",
		register1: "登録",
		register2: "登録へ",
		forget: "パスワードを忘れましたか？",
		nextstep: "次へ",
		upstep: "前へ",
		phone: "電話番号",
		phone1: "電話番号を入力してください",
		phone2: "電話認証コード",
		phone3: "電話番号確認",
		code: "認証コードが無効です",
		code1: "認証コード",
		code2: "認証コードください",
		code3: "認証コードを再送信してください",
		toast: "登録成功",
		toast1: "変更成功",
		toast2: "送信成功",
		findphone: "電話で回復",
		findemail: "メールで回復",
		changepass: "パスワード変更",
		findpass: "パスワードを忘れた",
		surechange: "変更を確認",
		areaCode1:'市外局番を選択',
	},
	shopMall: {
		shopHome: "ホーム",
		startSelling: "予約販売開始",
		selling: "販売中",
		willStart: "近日開始",
		sellOut: "売り切れ",
		privacy: "プライバシーポリシー",
		service: "サービス利用規約",
		cookieNotice:
			"より良い体験のためにCookieを使用しています。「受け入れる」をクリックしてサイトをご利用ください。",
		cookieReject: "拒否",
		cookieAccept: "受け入れる",
		introTitle: "JJ20 is Coming to Your NEIGHBORHOOD!",
		introContent01:
			"In celebration of JJ Lin's 20th anniversary in the music industry, we're excited to announce a special collaboration with NEIGHBORHOOD, a brand JJ has admired and supported for years. This partnership is even more significant as NEIGHBORHOOD marks its own milestone of 30 years in style and culture.",
		introContent02: "As the JJ20 World Tour prepares to hit Tokyo, it’s the perfect moment to bring the spirit of Ura-Harajuku to the stage.",
		introContent03: 'Introducing the JJ20 x NEIGHBORHOOD Collection: a limited-edition series that celebrates music, Tokyo’s underground culture, and the legacy of two iconic anniversaries.',
		number: "数量",
		addCart: "カートに追加",
		userNotPay: "支払いがされていないユーザーがあります",
		againBuy: "後で再度",
		noneGoods: "カートに商品がありません",
		shoppingLook: "ショッピングを続ける",
		goOnShoping: "ショッピングを続ける",
		showAllGoods: "全ての無効商品を表示",
		settleAccounts: "チェックアウト",
		notice: "ヒント",
		deleteGoodsNotice: "この商品を削除してもよろしいですか",
		cancel: "キャンセル",
		remove: "削除",
		removeSuccess: "削除成功",
		shopDetail: "詳細",
		shopCart: "カート",
		goodsSellOut: "商品は売り切れました",
		addSuccess: "カートに追加成功",
		buyNotice: "購入に関する注意",
		selectText: "選択してください",
		selectSkuTips: "購入する商品を選択してください",
		willSell: "販売開始予定",
		noSkuTips: "在庫が不足しています。後で再度お試しください",
		settleAccountsTips: "チェックアウトする商品を選択してください",
		exceedLimitNum: '購入制限超過',
		miniNum: '最少1点購入',
	},
	newadd: {
		address1: "デフォルトの住所",
		pc1: "受取人名を入力してください",
		pc2: "都道府県市区町村を選択してください",
		pc3: "郵便番号を入力してください",
		pc4: "電話番号を入力してください",
		pc5: "詳細住所を入力してください",
		confirm: "確認",
		delete: "この住所を削除しますか",
		ordernew: "支払い待ち",
		handlefail:'ネットワーク接続が不安定です',
		handleHome:	'ホームページ',
	},
	privacy: {
		line1: 'Last Updated: September 16, 2024',
		line2: 'Effective Date: September 16, 2024',
		line3: 'The “JJ20XNEIGHBORHOOD商城” service (“Service”) is provided by SHANGHAI OROTA TECH CO., LTD. (“we” or “us”). We highly value your privacy and the protection of your personal data. Based on relevant laws, regulations, and the “JJ20XNEIGHBORHOOD商城” Terms of Service, we have formulated this privacy policy (“Privacy Policy”) to provide you with more comprehensive service content.',
		line4: 'Before using the Service, we strongly encourage you to carefully read and fully understand all provisions in this Privacy Policy. By initiating any form of the Service, you will be deemed to have agreed to and accepted all provisions of this Privacy Policy.',
		line5: 'If you are under the age of 18, please take ample time to carefully read and fully understand all provisions in this Privacy Policy in the presence of your legal representative or guardian. By using any part of the Service, you will be considered to have obtained the consent of your legal representative or guardian to accept all provisions in this Privacy Policy.',
		line6: '1、We may change any content of this Privacy Policy and will notify you through announcements, emails, or other reasonable means. If you do not agree with this Privacy Policy, please stop using the Service immediately. Once you continue to use the Service after the changes, it will be deemed that you have agreed to accept the changes to this Privacy Policy.',
		line7: '2、Within the specific purpose of marketing, promotion, and execution of business related to the Service and the management, investigation, research, analysis, and improvement of the aforementioned related business in the operating region and period of our entity, you agree that we may collect, process, utilize and cross-border transfer the following personal data (“Personal Data”) provided by you in any form and manner when registering, logging in and using the Service:',
		line8: '(1) Basic information: including name, gender, birthday, address, phone number and email address, etc.',
		line9: '(2) Identity information: including ID number, passport number and other relevant identity certificates, as well as profile pictures, height, age and other related characteristics.',
		line10: '(3) Device data: including hardware model, device MAC address, operating system type, software list, international mobile device identification code or running process information, etc.',
		line11: 'Furthermore, we will provide the Personal Data to third parties or government agencies within or outside our operating regions under the following circumstances:',
		line12: '(1) We share or transfer the Personal Data to our affiliates, related companies, and cooperating third parties as stipulated in this paragraph. The entities and third parties may use the Personal Data according to the purpose outlined in this paragraph;',
		line13: '(2) We provide the Personal Data to government agencies upon request (including but not limited to tax authorities, customs authorities, or judicial authorities) or as necessary to cooperate in cases where you are suspected of criminal activity.',
		line14: '3、To provide you with better services and optimize your experience, we will record your device data when you use the Service and related applications. The device data includes your IP address, browsing history, cookies, and other information. It will be used to analyze preferences and habits, to count the number of visitors and traffic, and to adjust service content based on the results of these analyses and statistics.',
		line15: '4、To provide the Service, we may use third-party SDKs (including but not limited to those for payment processing, SMS verification, and message push notifications). You agree that these SDKs may collect or use your personal information as necessary for us to provide the mentioned services. We will disclose the names and relevant information of the SDKs used through appropriate interfaces and means as needed.',
		line16: '5、We will employ technology and procedures in compliance with laws and regulations to safeguard the security of the Personal Data, preventing it from being stolen, tampered with, damaged, lost, or leaked. You understand and agree that when you use the Service via the internet, various malicious means may exist. Despite our effort to protect the Personal Data through the aforementioned technology and procedures, it cannot guarantee the security of the Personal Data due to potential malicious activities over the internet. You must take proactive security measures (including but not limited to regularly changing account passwords) and assume the associated risks and responsibilities.',
		line17: "6、You may request from we to inquire about, access, or obtain copies of the Personal Data, as well as to supplement, correct, request cessation of collection, processing, or utilization, or request deletion. We may charge necessary processing fees for your requests regarding inquiry, access, or provision of copies. If you need to delete your account, please submit a request form by following the steps below and send it via email to: NBHD@orota.cn。",
		line18: '7、We place great importance on the protection of personal information of minors and will protect the personal information of minors in accordance with laws and regulations. If we discover that it has collected the personal information of minors without the prior consent from their legal guardians, it will attempt to delete the relevant information as soon as possible.',
		line19: "8、If you have any questions, opinions, or suggestions regarding this Privacy Policy, please contact us via the email: NBHD@orota.cn. We will generally reply within 5 business days. In the event of any dispute arising from this Privacy Policy, you agree to first negotiate in good faith with us. If both parties fail to reach an agreement through negotiation and resort to litigation, you agree that the Minhang District People's Court of Shanghai shall have jurisdiction.",
		line20: '9、CONTACT',
		line21: "Email: NBHD@orota.cn",
	},
	service: {
		line1: 'Last Updated: September 16, 2024',
		line2: 'Effective Date: September 16, 2024',
		line3: 'The "JJ20XNEIGHBORHOOD商城" service (“Service”) is provided by SHANGHAI OROTA TECH CO., LTD. (“we” or “us”). These Terms of Service (“TOS”) constitute the agreement between you and us regarding your account registration and use of the Service.',
		line4: 'Before using the Service, we strongly encourage you to carefully read and fully understand all provisions in these TOS. By initiating any form of the Service, you will be considered to have agreed to and accepted all provisions in these TOS.',
		line5: 'If you are under the age of 18, please take ample time to carefully read and fully understand all provisions in these TOS in the presence of your legal representative or guardian. By using any part of the Service, you will be considered to have obtained the consent of your legal representative or guardian to accept all provisions in these TOS.',
		line6: '1、INTRODUCTION',
		line7: '1.1 We reserve the right to transfer or assign any part or all of the rights and obligations under these TOS to our affiliates, partners, or third parties cooperating with us for operating, managing, and fulfilling the Service.',
		line8: '1.2 We reserve the right to amend or terminate any provisions of these TOS. We will notify you of such changes through a general announcement or other reasonable means. These changes will take effect regardless of whether you receive notification. If you continue to use the Service after such changes, you will be deemed to have agreed to and accepted the amendments or terminations of these TOS.',
		line9: '2、SERVICE CONTENT',
		line10: '2.1 The specifics of the Service will be announced by us based on actual conditions, including but not limited to event bookings. We reserve the right to update, adjust, or perform maintenance on the Service at any time, which may include functional upgrades, improvements, restrictions, and new services. By using the Service, you agree that we may not provide specific notifications regarding such updates, adjustments, or maintenance.',
		line11: '2.2 Due to the inherent nature of the Internet (including but not limited to server instability, malicious cyber-attacks, and other factors beyond our control), we reserve the right to partially or entirely suspend or terminate the Service. In such cases, we may notify you through a general announcement or other reasonable means at our discretion.',
		line12: '2.3 We provide the Service via the Internet. You agree and accept that you are solely responsible for the equipment (such as smartphones, computers, tablets, etc.) used to access the Service and for any associated fees (including phone bills and data charges) incurred through your Internet connection.',
		line13: '2.4 Products and Orders: ',
		line14: "(1) Before placing an order, please carefully verify the title, price, quantity, model, specifications, size of the items you are purchasing, as well as your contact address, phone number, and recipient details. If the recipient is not you, please note that the recipient's actions and representations will be considered as your own, and you will be jointly liable for the recipient's actions and representations.",
		line15: '(2) The product sales page is for displaying the products. After receiving your order, the actual provider of the product will decide whether to accept your order based on factors such as product availability. Once the actual provider accepts your order (note: automatic notifications or verification messages generated during the ordering process do not necessarily indicate that the order has been accepted), a purchase contract for the product is formed between you and the provider, who will then ship the product according to the order.',
		line16: '(3) Despite reasonable commercial efforts, market changes are unavoidable and may impact the fulfillment of your order. If the actual provider encounters stock shortages or other issues impeding fulfillment after accepting your order, the provider has the right to negotiate with you to cancel the order or the purchase contract. If you have already made a payment, a refund will be processed for you.',
		line17: '3、ACCOUNT GUIDELINES',
		line18: '3.1 The username and password you set during the registration process of your account ("Account") are your credentials for accessing the Service. Please do not disclose your username and password to anyone under any circumstances. Please update your password regularly and take appropriate measures to protect the privacy and security of your Account. You acknowledge and agree that you are responsible and liable for all activities conducted using your Account. If you suspect or discover any unauthorized use of your Account or other security issues, please contact us promptly.',
		line19: "3.2 Please ensure that you register the Account using accurate personal information and do not fabricate or impersonate others' personal or company information, including but not limited to using false names, avatars, or profiles. Please update your Account to reflect any changes in your personal information. If there is any fabrication or impersonation, or if we have reasonable grounds to suspect that the Account data is incorrect, untrue, or illegal, we may refuse your access to the Service or restrict your use of certain functions. You agree not to raise any objections or claims in such cases.",
		line20: '3.3 You understand that the ownership and related rights of the Account belong to us. Upon registration, you are granted the right to use the Account, which is only applicable to the initial registrant. If you provide the Account to anyone through any means such as gifting, borrowing, renting, transferring, or selling, we are entitled to suspend, terminate, or cancel the Account without prior notice if we discover or reasonably suspect that the user of the Account is not the initial registrant.',
		line21: '3.4 If you forget your username or password, you can apply to retrieve them through the procedures announced by us. You understand that these procedures only verify that the data provided by the applicant matches the records of the Service and are not designed to confirm whether the applicant is the genuine user of the Account. You should not assume that we have verified the applicant as the genuine user of the Account based on these procedures.',
		line22: '4、CODE OF CONDUCT',
		line23: '4.1 You acknowledge and agree that you are responsible and liable for all actions conducted under the Account, including but not limited to violations of laws, breaches of these TOS, and disruptions to transaction order, as well as any consequences arising from such actions.',
		line24: '4.2 You agree not to engage in the following behaviors:',
		line25: '(1) Impersonating or using someone else’s identity;',
		line26: '(2) Using improper means to achieve trading or participation goals, such as employing bots, simulators, or similar tools;',
		line27: '(3) Exploiting technical glitches or loopholes in these TOS to place orders or obtain benefits and subsidies;',
		line28: '(4) Engaging in malicious returns or exchanges to gain unjust benefits;',
		line29: '(5) Placing or canceling orders excessively and unnaturally to disrupt the flow of goods and interfere with transaction order;',
		line30: "(6) Violating public morals, laws, regulations, or infringing on others' rights;",
		line31: "(7) Any other behavior deemed by us to affect others' rights or be unsuitable for the Service.",
		line32: '4.3、During your use of the Service and related applications, you agree not to engage in the following behaviors:',
		line33: '(1) Frequent or excessive registration of accounts or any other actions that disrupt the program;',
		line34: "(2) Reverse engineering, reverse assembly, reverse compilation, or any other attempt to discover the program's source code;",
		line35: '(3) Using plugins, cheats, or any third-party tools/services to replicate, modify, add, delete, or attach program data, or create any derivative works;',
		line36: '(4) Engaging in any other behaviors unauthorized by us.',
		line37: '4.4 If your use of the Service involves services provided by third parties, you agree to comply not only with these TOS but also with the relevant conduct and rules of the service provided by third parties. Disputes and losses arising from the services provided by third parties shall be resolved between you and the said third parties.',
		line38: '4.5 If there is any violation of this Code of Conduct by you or if we have reasonable grounds to suspect that your behavior may violate this Code of Conduct, we may refuse your use of the Service or suspend, terminate, or cancel your account, and hold you accountable for related responsibilities and liabilities.',
		line39: '5、TERMS FOR MINORS',
		line40: "5.1 If you are under 18 years old, you should use this Service under the guidance of your legal guardian and adhere to the following guidelines:",
		line41: '(1) Be diligent in online learning and refrain from browsing inappropriate content;',
		line42: '(2) Engage in honest and friendly communication, refraining from insulting or deceiving others;',
		line43: '(3) Enhance self-protection awareness and refrain from meeting online friends casually;',
		line44: '(4) Maintain network security and refrain from disrupting network order;',
		line45: '(5) Promote physical and mental health and refrain from indulging in virtual spaces.',
		line46: '5.2 We place great importance on the protection of minors. If minors wish to engage in any consumption activities using the Service, it is essential to do so with the accompaniment and explicit consent of their legal guardian or custodian.',
		line47: '6、PRIVACY POLICY',
		line48: '6.1 We place great importance on your personal information and privacy. We will utilize appropriate security technologies and other security measures commensurate with the Service to protect your personal information.',
		line49: '6.2 Please take sufficient time to read and fully understand all the contents of our “JJ20XNEIGHBORHOOD商城” privacy policy. If you do not agree to such content, it may result in the Service not being able to operate normally, or may restrict certain functions. Once you start using the Service in any form or manner, you will be considered to have agreed to and accepted all the contents of such privacy policy.',
		line50: '7、ADVERTISEMENTS',
		line51: '7.1 You understand that the content of the Service may include advertising services provided by us for individuals or businesses. You agree that we may display commercial advertisements, promotions, or information (including commercial or non-commercial information) from Service partners, sponsors, or suppliers in the Service.',
		line52: '7.2 We will display advertisements in accordance with relevant laws and regulations. You should independently assess the authenticity and reliability of such advertisements, promotions, and information, and you shall be responsible for your judgments. Except where otherwise provided by laws and regulations, you shall bear the responsibility for any purchases or transactions made or damages incurred as a result of such purchases or transactions related to advertisements, promotions, and information.',
		line53: '8、INTELLECTUAL PROPERTY RIGHTS',
		line54: '8.1 We hold full rights to all content provided by the Service and its related applications, as well as all program codes and information generated during their development and operation. Without prior written consent from us, you may not use the aforementioned content, program codes, and information in any manner or form (including but not limited to copying, displaying, distributing, monitoring, mirroring, uploading, or downloading through any program or device).',
		line55: '8.2 You should not display, use, or apply to register trademarks, domain names, website names, or other prominent brand features of us (“Marks”) separately or in combination in any way. You should not engage in any behavior indicating or implying that you have the right to display, use, or otherwise deal with these Marks. If you cause any loss to, we or any third party as a result, you should bear full responsibility and liabilities.',
		line56: '9、BREACH',
		line57: '9.1 In the event of any violation of these TOS by you, we may, at our sole discretion and without prior notice, delete or block relevant content at any time. Depending on the severity of the violation, we may impose measures such as restricting certain or all functions of the offending account, suspending the account, or terminating the account, and we will announce the results of these actions. For actions suspected of violating laws and regulations, we will retain relevant records and cooperate with competent authorities in their investigations. You will be responsible for all legal consequences arising from such violations.',
		line58: '9.2 You understand and agree that you are solely responsible for any claims, demands, or losses brought by any third party arising from your violation of these TOS. If we, our affiliates, or partner companies incur any loss as a result of such violations (including but not limited to economic or reputational losses), you will be liable for all indemnification and compensation.',
		line59: '10、DISCLAIMER',
		line60: '10.1 Please adhere to all applicable laws, regulations, and governmental policies while using the Service. You will be solely responsible and liable for any consequences resulting from your violations.',
		line61: '10.2 You understand that content accessible through external links is not controlled by us and agree that we do not guarantee its accuracy or completeness.',
		line62: '10.3 We shall not be held liable for interruptions or other defects caused by force majeure.',
		line63: '10.4 The Service is provided by us based on current technology and conditions. We will make our effort to ensure the continuity and security of the Service. You understand and agree that we cannot foresee or prevent all legal, technical, and other risks at all times, including but not limited to malware, viruses, hacker attacks, system instability, third-party service defects, force majeure, and other security issues that may result in service interruptions, data loss, and other risks. We may modify, interrupt, suspend, or terminate the Service as needed for operational purposes after appropriate notification, without bearing any liability for compensation.',
		line64: '11、MISCELLANEOUS',
		line65: "11.1 These TOS shall be governed by and construed in accordance with the laws and regulations of People’s Republic of China. In the event of any dispute arising from the Service and the Terms of Service, you agree to first negotiate in good faith with us. If both parties fail to reach an agreement through negotiation and resort to litigation, you agree that the Minhang District People's Court of Shanghai shall have jurisdiction.",
		line66: '11.2 The headings of these TOS are provided for convenience only and have no substantive meaning; they shall not be used as a basis for interpreting the provisions herein.',
		line67: '11.3 If any provision of these TOS is wholly or partially invalid or unenforceable for any reason, the remaining provisions of the TOS shall remain valid and binding.',
		line68: '12、CONTACT',
		line69: "Email:NBHD@orota.cn",
	}
};
