export const message = {
	language: "EN",
	mine: {
		mine1: "My Orders",
		mine2: "Account Settings",
		mine3: "My Addresses",
		mine4: "Customer Support",
		mine5: "Log Out",
		order1: "All",
		order2: "Unpaid",
		order3: "Unshipped",
		order4: "Completed",
		order11: "Undelivered",
		order12: "Closed",
		finished: "No More",
		loading: "Loading...",
		nodata: "No Orders Yet",
		order5: "Order Number",
		order6: "Style",
		order7: "Quantity",
		order8: "Total",
		order9: "Items",
		order10: "Total Amount",
		pay1: "Order Closes in 5 Minutes",
		pay2: "Change Payment Method",
		pay3: "Pay Now",
		pay4: "Confirm Payment",
		pay5: "Please Choose a Payment Method",
		pay6: "After-Sales Support",
		pay7: "Track Shipment",
		log1: "Check your logistics information on the carrier's website by entering the tracking number.",
		log2: "Logistics Provider",
		log3: "Tracking Number",
		log4: "Confirm",
		after1: "Online Hours: 10:30 - 18:00 (Excluding Holidays)",
		copy: "Copy Success",
		detail1: "Please complete payment; the order will be canceled if unpaid within 5 minutes.",
		detail2: "Order Details",
		detail3: "Subtotal",
		detail4: "Shipping Fee",
		detail5: "Payment Platform Fee",
		detail6: "Order Number",
		detail7: "Summary",
		detail8: "Status",
		detail9: "Payment Method",
		detail10: "Ship To",
		detail11: "Order Date",
		confirm1: "Confirm Order",
		confirm2: "Shipping Service",
		confirm4: "Order Details",
		confirm5: "Submit Order",
		confirm6: "Add New Address",
		tip1: "Notice",
		tip2: "I acknowledge and agree to place the order",
		address1: "Address List",
		address2: "Add Address",
		address3: "Add New Address",
		address4: "Edit",
		address5: "Delete",
		address6: "Recipient Name",
		address7: "State/City/District",
		address8: "Postal Code",
		address9: "Phone Number",
		address10: "Detailed Address",
		address11: "Set as Default",
		address12: "Save",
		support1: "After-Sales Support",
		support2: "FAQs",
		support3: "Send Email to",
		support4:
			"Our customer service is available from 10:30-18:00 (excluding holidays) to assist with after-sales inquiries.",
		tip3: "No returns or exchanges for overseas products.",
		tip4: "Please confirm product information carefully before purchase,",
		tip5: "Ensure accuracy before purchasing.",
		tip6: "Thank you for your understanding and support.",
		order13: "Shipping Address",
		order14: "Please fill in your address for delivery",
		order15: "Choose Your Payment Method",
		order16: "Confirm Your Order Details",
		order17: "Sales Not Supported in This Region",
		paying1: "Processing Payment...",
		paying2: "Payment in Progress...",
		paying3: "Do not close this page, please wait",
		mine: "Mine",
		noorder: "No Orders",
		password: "Old Password",
		password1: "New Password",
		password2: "Enter Old Password",
		password3: "Enter New Password",
		password4: "Re-enter New Password",
		password5: "Old Password Incorrect",
		pay8: "Remaining Payment Time",
		logout1: "Confirm Logout?",
		cancel: "Cancel",
		showtips: "Log in to the JJ20×NEIGHBORHOOD site using phone or email",
		accountDetail: "Details",
	},
	login: {
		email: "Email",
		email1: "Re-enter Email",
		email2: "Re-enter Email",
		email3: "Enter Email",
		email4: "Enter Valid Email",
		email5: "Emails Do Not Match",
		email6: "Email Verification Code",
		email7: "Verification Code",
		email8: "Get Code",
		email9: "Confirm Email",
		submitTips:
			"If you can't find the verification email in your inbox, please check your spam or junk folders. Sometimes, due to email filtering, our emails may be incorrectly categorized. If you still can't find it, try resending the code or contact customer support for assistance.",
		password: "Password",
		password1: "Enter Password",
		password2: "Re-enter Password",
		password3: "At least",
		password4: "letters/numbers/symbols",
		password5: "Two types required",
		password6: "and at least 8 characters",
		password7: "Confirm Password",
		password8: "Password Strength",
		password9: "Low Password Strength",
		password10: "Passwords Do Not Match",
		account: "Account",
		account1: "Enter Account",
		account2: "Email/Phone Number",
		account3: "Confirm Account Information",
		account4: "Enter Valid Account",
		account5: "Invalid Account or Password",
		account6: "Enter Valid Number",
		agree: "I have read and agree to the",
		agree1: "User Agreement",
		agree2: " and ",
		agree3: "Privacy Policy",
		agree4: "Please check the User Agreement and Privacy Policy",
		login: "Login",
		register: "No account?",
		register1: "Sign Up",
		register2: "Go to Sign Up",
		forget: "Forgot Password?",
		nextstep: "Next Step",
		upstep: "Previous Step",
		phone: "Phone Number",
		phone1: "Enter Phone Number",
		phone2: "Phone Verification Code",
		phone3: "Confirm Phone Number",
		code: "Invalid Code",
		code1: "Verification Code",
		code2: "Enter Code",
		code3: "Resend Code",
		toast: "Registration Successful",
		toast1: "Modification Successful",
		toast2: "Sent Successfully",
		findphone: "Recover with Phone",
		findemail: "Recover with Email",
		changepass: "Change Password",
		findpass: "Forgot Password",
		surechange: "Confirm",
		areaCode1:'Select area code',
	},
	shopMall: {
		shopHome: "Home",
		startSelling: "Pre-Sale",
		selling: "On Sale",
		willStart: "Starting Soon",
		sellOut: "Sold Out",
		privacy: "Privacy Policy",
		service: "Terms of Service",
		cookieNotice:
			"We use cookies to collect information and personalize content to optimize your experience. Please click 'Accept' to continue using our website.",
		cookieReject: "Reject",
		cookieAccept: "Accept",
		introTitle: "JJ20 is Coming to Your NEIGHBORHOOD!",
		introContent01:
			"In celebration of JJ Lin's 20th anniversary in the music industry, we're excited to announce a special collaboration with NEIGHBORHOOD, a brand JJ has admired and supported for years. This partnership is even more significant as NEIGHBORHOOD marks its own milestone of 30 years in style and culture.",
		introContent02: "As the JJ20 World Tour prepares to hit Tokyo, it’s the perfect moment to bring the spirit of Ura-Harajuku to the stage.",
		introContent03: 'Introducing the JJ20 x NEIGHBORHOOD Collection: a limited-edition series that celebrates music, Tokyo’s underground culture, and the legacy of two iconic anniversaries.',
		number: "Quantity",
		addCart: "Add to Cart",
		userNotPay: "Some users have unpaid items",
		againBuy: "Try Again Later",
		noneGoods: "No items in the cart",
		shoppingLook: "Go Shopping",
		goOnShoping: "Continue Shopping",
		showAllGoods: "Show All Unavailable Items",
		settleAccounts: "Checkout",
		notice: "Notice",
		deleteGoodsNotice: "Remove this item?",
		cancel: "Cancel",
		remove: "Remove",
		removeSuccess: "Removed Successfully",
		shopDetail: "Details",
		shopCart: "Cart",
		goodsSellOut: "Item Sold Out",
		addSuccess: "Added to Cart",
		buyNotice: "Purchase Notice",
		selectText: "Please Select",
		selectSkuTips: "Please select the item to purchase",
		willSell: "Starting Soon",
		noSkuTips: "Insufficient stock, please refresh later",
		settleAccountsTips: "Please select items to checkout",
		exceedLimitNum: 'Exceeds Purchase Limit',
		miniNum: 'Minimum 1 Item Required',
	},
	newadd: {
		address1: "Default",
		pc1: "Enter name",
		pc2: "Select region",
		pc3: "Enter postal code",
		pc4: "Enter phone number",
		pc5: "Enter address",
		confirm: "Confirm",
		delete: "Delete this address?",
		ordernew: "Unpaid",
		handlefail:'Network error',
		handleHome:'Back to Home',
	},
	privacy: {
		line1: 'Last Updated: September 16, 2024',
		line2: 'Effective Date: September 16, 2024',
		line3: 'The “JJ20XNEIGHBORHOOD商城” service (“Service”) is provided by SHANGHAI OROTA TECH CO., LTD. (“we” or “us”). We highly value your privacy and the protection of your personal data. Based on relevant laws, regulations, and the “JJ20XNEIGHBORHOOD商城” Terms of Service, we have formulated this privacy policy (“Privacy Policy”) to provide you with more comprehensive service content.',
		line4: 'Before using the Service, we strongly encourage you to carefully read and fully understand all provisions in this Privacy Policy. By initiating any form of the Service, you will be deemed to have agreed to and accepted all provisions of this Privacy Policy.',
		line5: 'If you are under the age of 18, please take ample time to carefully read and fully understand all provisions in this Privacy Policy in the presence of your legal representative or guardian. By using any part of the Service, you will be considered to have obtained the consent of your legal representative or guardian to accept all provisions in this Privacy Policy.',
		line6: '1、We may change any content of this Privacy Policy and will notify you through announcements, emails, or other reasonable means. If you do not agree with this Privacy Policy, please stop using the Service immediately. Once you continue to use the Service after the changes, it will be deemed that you have agreed to accept the changes to this Privacy Policy.',
		line7: '2、Within the specific purpose of marketing, promotion, and execution of business related to the Service and the management, investigation, research, analysis, and improvement of the aforementioned related business in the operating region and period of our entity, you agree that we may collect, process, utilize and cross-border transfer the following personal data (“Personal Data”) provided by you in any form and manner when registering, logging in and using the Service:',
		line8: '(1) Basic information: including name, gender, birthday, address, phone number and email address, etc.',
		line9: '(2) Identity information: including ID number, passport number and other relevant identity certificates, as well as profile pictures, height, age and other related characteristics.',
		line10: '(3) Device data: including hardware model, device MAC address, operating system type, software list, international mobile device identification code or running process information, etc.',
		line11: 'Furthermore, we will provide the Personal Data to third parties or government agencies within or outside our operating regions under the following circumstances:',
		line12: '(1) We share or transfer the Personal Data to our affiliates, related companies, and cooperating third parties as stipulated in this paragraph. The entities and third parties may use the Personal Data according to the purpose outlined in this paragraph;',
		line13: '(2) We provide the Personal Data to government agencies upon request (including but not limited to tax authorities, customs authorities, or judicial authorities) or as necessary to cooperate in cases where you are suspected of criminal activity.',
		line14: '3、To provide you with better services and optimize your experience, we will record your device data when you use the Service and related applications. The device data includes your IP address, browsing history, cookies, and other information. It will be used to analyze preferences and habits, to count the number of visitors and traffic, and to adjust service content based on the results of these analyses and statistics.',
		line15: '4、To provide the Service, we may use third-party SDKs (including but not limited to those for payment processing, SMS verification, and message push notifications). You agree that these SDKs may collect or use your personal information as necessary for us to provide the mentioned services. We will disclose the names and relevant information of the SDKs used through appropriate interfaces and means as needed.',
		line16: '5、We will employ technology and procedures in compliance with laws and regulations to safeguard the security of the Personal Data, preventing it from being stolen, tampered with, damaged, lost, or leaked. You understand and agree that when you use the Service via the internet, various malicious means may exist. Despite our effort to protect the Personal Data through the aforementioned technology and procedures, it cannot guarantee the security of the Personal Data due to potential malicious activities over the internet. You must take proactive security measures (including but not limited to regularly changing account passwords) and assume the associated risks and responsibilities.',
		line17: "6、You may request from we to inquire about, access, or obtain copies of the Personal Data, as well as to supplement, correct, request cessation of collection, processing, or utilization, or request deletion. We may charge necessary processing fees for your requests regarding inquiry, access, or provision of copies. If you need to delete your account, please submit a request form by following the steps below and send it via email to: NBHD@orota.cn。",
		line18: '7、We place great importance on the protection of personal information of minors and will protect the personal information of minors in accordance with laws and regulations. If we discover that it has collected the personal information of minors without the prior consent from their legal guardians, it will attempt to delete the relevant information as soon as possible.',
		line19: "8、If you have any questions, opinions, or suggestions regarding this Privacy Policy, please contact us via the email: NBHD@orota.cn. We will generally reply within 5 business days. In the event of any dispute arising from this Privacy Policy, you agree to first negotiate in good faith with us. If both parties fail to reach an agreement through negotiation and resort to litigation, you agree that the Minhang District People's Court of Shanghai shall have jurisdiction.",
		line20: '9、CONTACT',
		line21: "Email: NBHD@orota.cn",
	},
	service: {
		line1: 'Last Updated: September 16, 2024',
		line2: 'Effective Date: September 16, 2024',
		line3: 'The "JJ20XNEIGHBORHOOD商城" service (“Service”) is provided by SHANGHAI OROTA TECH CO., LTD. (“we” or “us”). These Terms of Service (“TOS”) constitute the agreement between you and us regarding your account registration and use of the Service.',
		line4: 'Before using the Service, we strongly encourage you to carefully read and fully understand all provisions in these TOS. By initiating any form of the Service, you will be considered to have agreed to and accepted all provisions in these TOS.',
		line5: 'If you are under the age of 18, please take ample time to carefully read and fully understand all provisions in these TOS in the presence of your legal representative or guardian. By using any part of the Service, you will be considered to have obtained the consent of your legal representative or guardian to accept all provisions in these TOS.',
		line6: '1、INTRODUCTION',
		line7: '1.1 We reserve the right to transfer or assign any part or all of the rights and obligations under these TOS to our affiliates, partners, or third parties cooperating with us for operating, managing, and fulfilling the Service.',
		line8: '1.2 We reserve the right to amend or terminate any provisions of these TOS. We will notify you of such changes through a general announcement or other reasonable means. These changes will take effect regardless of whether you receive notification. If you continue to use the Service after such changes, you will be deemed to have agreed to and accepted the amendments or terminations of these TOS.',
		line9: '2、SERVICE CONTENT',
		line10: '2.1 The specifics of the Service will be announced by us based on actual conditions, including but not limited to event bookings. We reserve the right to update, adjust, or perform maintenance on the Service at any time, which may include functional upgrades, improvements, restrictions, and new services. By using the Service, you agree that we may not provide specific notifications regarding such updates, adjustments, or maintenance.',
		line11: '2.2 Due to the inherent nature of the Internet (including but not limited to server instability, malicious cyber-attacks, and other factors beyond our control), we reserve the right to partially or entirely suspend or terminate the Service. In such cases, we may notify you through a general announcement or other reasonable means at our discretion.',
		line12: '2.3 We provide the Service via the Internet. You agree and accept that you are solely responsible for the equipment (such as smartphones, computers, tablets, etc.) used to access the Service and for any associated fees (including phone bills and data charges) incurred through your Internet connection.',
		line13: '2.4 Products and Orders: ',
		line14: "(1) Before placing an order, please carefully verify the title, price, quantity, model, specifications, size of the items you are purchasing, as well as your contact address, phone number, and recipient details. If the recipient is not you, please note that the recipient's actions and representations will be considered as your own, and you will be jointly liable for the recipient's actions and representations.",
		line15: '(2) The product sales page is for displaying the products. After receiving your order, the actual provider of the product will decide whether to accept your order based on factors such as product availability. Once the actual provider accepts your order (note: automatic notifications or verification messages generated during the ordering process do not necessarily indicate that the order has been accepted), a purchase contract for the product is formed between you and the provider, who will then ship the product according to the order.',
		line16: '(3) Despite reasonable commercial efforts, market changes are unavoidable and may impact the fulfillment of your order. If the actual provider encounters stock shortages or other issues impeding fulfillment after accepting your order, the provider has the right to negotiate with you to cancel the order or the purchase contract. If you have already made a payment, a refund will be processed for you.',
		line17: '3、ACCOUNT GUIDELINES',
		line18: '3.1 The username and password you set during the registration process of your account ("Account") are your credentials for accessing the Service. Please do not disclose your username and password to anyone under any circumstances. Please update your password regularly and take appropriate measures to protect the privacy and security of your Account. You acknowledge and agree that you are responsible and liable for all activities conducted using your Account. If you suspect or discover any unauthorized use of your Account or other security issues, please contact us promptly.',
		line19: "3.2 Please ensure that you register the Account using accurate personal information and do not fabricate or impersonate others' personal or company information, including but not limited to using false names, avatars, or profiles. Please update your Account to reflect any changes in your personal information. If there is any fabrication or impersonation, or if we have reasonable grounds to suspect that the Account data is incorrect, untrue, or illegal, we may refuse your access to the Service or restrict your use of certain functions. You agree not to raise any objections or claims in such cases.",
		line20: '3.3 You understand that the ownership and related rights of the Account belong to us. Upon registration, you are granted the right to use the Account, which is only applicable to the initial registrant. If you provide the Account to anyone through any means such as gifting, borrowing, renting, transferring, or selling, we are entitled to suspend, terminate, or cancel the Account without prior notice if we discover or reasonably suspect that the user of the Account is not the initial registrant.',
		line21: '3.4 If you forget your username or password, you can apply to retrieve them through the procedures announced by us. You understand that these procedures only verify that the data provided by the applicant matches the records of the Service and are not designed to confirm whether the applicant is the genuine user of the Account. You should not assume that we have verified the applicant as the genuine user of the Account based on these procedures.',
		line22: '4、CODE OF CONDUCT',
		line23: '4.1 You acknowledge and agree that you are responsible and liable for all actions conducted under the Account, including but not limited to violations of laws, breaches of these TOS, and disruptions to transaction order, as well as any consequences arising from such actions.',
		line24: '4.2 You agree not to engage in the following behaviors:',
		line25: '(1) Impersonating or using someone else’s identity;',
		line26: '(2) Using improper means to achieve trading or participation goals, such as employing bots, simulators, or similar tools;',
		line27: '(3) Exploiting technical glitches or loopholes in these TOS to place orders or obtain benefits and subsidies;',
		line28: '(4) Engaging in malicious returns or exchanges to gain unjust benefits;',
		line29: '(5) Placing or canceling orders excessively and unnaturally to disrupt the flow of goods and interfere with transaction order;',
		line30: "(6) Violating public morals, laws, regulations, or infringing on others' rights;",
		line31: "(7) Any other behavior deemed by us to affect others' rights or be unsuitable for the Service.",
		line32: '4.3、During your use of the Service and related applications, you agree not to engage in the following behaviors:',
		line33: '(1) Frequent or excessive registration of accounts or any other actions that disrupt the program;',
		line34: "(2) Reverse engineering, reverse assembly, reverse compilation, or any other attempt to discover the program's source code;",
		line35: '(3) Using plugins, cheats, or any third-party tools/services to replicate, modify, add, delete, or attach program data, or create any derivative works;',
		line36: '(4) Engaging in any other behaviors unauthorized by us.',
		line37: '4.4 If your use of the Service involves services provided by third parties, you agree to comply not only with these TOS but also with the relevant conduct and rules of the service provided by third parties. Disputes and losses arising from the services provided by third parties shall be resolved between you and the said third parties.',
		line38: '4.5 If there is any violation of this Code of Conduct by you or if we have reasonable grounds to suspect that your behavior may violate this Code of Conduct, we may refuse your use of the Service or suspend, terminate, or cancel your account, and hold you accountable for related responsibilities and liabilities.',
		line39: '5、TERMS FOR MINORS',
		line40: "5.1 If you are under 18 years old, you should use this Service under the guidance of your legal guardian and adhere to the following guidelines:",
		line41: '(1) Be diligent in online learning and refrain from browsing inappropriate content;',
		line42: '(2) Engage in honest and friendly communication, refraining from insulting or deceiving others;',
		line43: '(3) Enhance self-protection awareness and refrain from meeting online friends casually;',
		line44: '(4) Maintain network security and refrain from disrupting network order;',
		line45: '(5) Promote physical and mental health and refrain from indulging in virtual spaces.',
		line46: '5.2 We place great importance on the protection of minors. If minors wish to engage in any consumption activities using the Service, it is essential to do so with the accompaniment and explicit consent of their legal guardian or custodian.',
		line47: '6、PRIVACY POLICY',
		line48: '6.1 We place great importance on your personal information and privacy. We will utilize appropriate security technologies and other security measures commensurate with the Service to protect your personal information.',
		line49: '6.2 Please take sufficient time to read and fully understand all the contents of our “JJ20XNEIGHBORHOOD商城” privacy policy. If you do not agree to such content, it may result in the Service not being able to operate normally, or may restrict certain functions. Once you start using the Service in any form or manner, you will be considered to have agreed to and accepted all the contents of such privacy policy.',
		line50: '7、ADVERTISEMENTS',
		line51: '7.1 You understand that the content of the Service may include advertising services provided by us for individuals or businesses. You agree that we may display commercial advertisements, promotions, or information (including commercial or non-commercial information) from Service partners, sponsors, or suppliers in the Service.',
		line52: '7.2 We will display advertisements in accordance with relevant laws and regulations. You should independently assess the authenticity and reliability of such advertisements, promotions, and information, and you shall be responsible for your judgments. Except where otherwise provided by laws and regulations, you shall bear the responsibility for any purchases or transactions made or damages incurred as a result of such purchases or transactions related to advertisements, promotions, and information.',
		line53: '8、INTELLECTUAL PROPERTY RIGHTS',
		line54: '8.1 We hold full rights to all content provided by the Service and its related applications, as well as all program codes and information generated during their development and operation. Without prior written consent from us, you may not use the aforementioned content, program codes, and information in any manner or form (including but not limited to copying, displaying, distributing, monitoring, mirroring, uploading, or downloading through any program or device).',
		line55: '8.2 You should not display, use, or apply to register trademarks, domain names, website names, or other prominent brand features of us (“Marks”) separately or in combination in any way. You should not engage in any behavior indicating or implying that you have the right to display, use, or otherwise deal with these Marks. If you cause any loss to, we or any third party as a result, you should bear full responsibility and liabilities.',
		line56: '9、BREACH',
		line57: '9.1 In the event of any violation of these TOS by you, we may, at our sole discretion and without prior notice, delete or block relevant content at any time. Depending on the severity of the violation, we may impose measures such as restricting certain or all functions of the offending account, suspending the account, or terminating the account, and we will announce the results of these actions. For actions suspected of violating laws and regulations, we will retain relevant records and cooperate with competent authorities in their investigations. You will be responsible for all legal consequences arising from such violations.',
		line58: '9.2 You understand and agree that you are solely responsible for any claims, demands, or losses brought by any third party arising from your violation of these TOS. If we, our affiliates, or partner companies incur any loss as a result of such violations (including but not limited to economic or reputational losses), you will be liable for all indemnification and compensation.',
		line59: '10、DISCLAIMER',
		line60: '10.1 Please adhere to all applicable laws, regulations, and governmental policies while using the Service. You will be solely responsible and liable for any consequences resulting from your violations.',
		line61: '10.2 You understand that content accessible through external links is not controlled by us and agree that we do not guarantee its accuracy or completeness.',
		line62: '10.3 We shall not be held liable for interruptions or other defects caused by force majeure.',
		line63: '10.4 The Service is provided by us based on current technology and conditions. We will make our effort to ensure the continuity and security of the Service. You understand and agree that we cannot foresee or prevent all legal, technical, and other risks at all times, including but not limited to malware, viruses, hacker attacks, system instability, third-party service defects, force majeure, and other security issues that may result in service interruptions, data loss, and other risks. We may modify, interrupt, suspend, or terminate the Service as needed for operational purposes after appropriate notification, without bearing any liability for compensation.',
		line64: '11、MISCELLANEOUS',
		line65: "11.1 These TOS shall be governed by and construed in accordance with the laws and regulations of People’s Republic of China. In the event of any dispute arising from the Service and the Terms of Service, you agree to first negotiate in good faith with us. If both parties fail to reach an agreement through negotiation and resort to litigation, you agree that the Minhang District People's Court of Shanghai shall have jurisdiction.",
		line66: '11.2 The headings of these TOS are provided for convenience only and have no substantive meaning; they shall not be used as a basis for interpreting the provisions herein.',
		line67: '11.3 If any provision of these TOS is wholly or partially invalid or unenforceable for any reason, the remaining provisions of the TOS shall remain valid and binding.',
		line68: '12、CONTACT',
		line69: "Email:NBHD@orota.cn",
	}
};
