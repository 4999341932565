<template>
    <div class="dialog_pop flex_box">
        <div class="dialog_center_plate">
            <p class="notice_title">{{title}}</p>
            <p class="notice_content">{{content}}</p>
            <div class="flex_box dialog_button_plate">
                <p v-if="cancelText" class="dislog_bottom_button flex_box" @click="dialogCancel">
                    {{ cancelText }}
                </p>
                <p v-if="confirmText" class="dislog_bottom_button flex_box" @click="dialogConfirm">
                    {{ confirmText }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, getCurrentInstance, defineProps, defineExpose } from "vue";
const props = defineProps(["title", "content", "cancelText", "confirmText", "cancel", "confirm"]);
const dialogCancel =()=> {
    props.cancel();
};
const dialogConfirm =()=> {
    props.confirm();
};
</script>

<style lang="less" scoped>
.flex_box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dialog_pop {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 22;
    .dialog_center_plate {
        flex: 1;
        margin: 0 39px;
        background-color: #1D1D1D;
        border: 1px #999999 solid;
        overflow: hidden;
        .notice_title {
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            margin-top: 20px;
        }
        .notice_content {
            font-size: 12px;
            font-weight: 500;
            color: #999999;
            margin: 10px;
            margin-bottom: 0;
            text-align: center;
        }
        .dialog_button_plate {
            margin-top: 20px;
            position: relative;
            &::before {
                content: '';
                width: 100%;
                height: 1px;
                background-color: #999999;
                position: absolute;
                top: 0;
                left: 0;
            }
            .dislog_bottom_button {
                flex: 1;
                padding: 16px 0;
                font-size: 14px;
                color: #FFFFFF;
                font-weight: 500;
                position: relative;
                &::after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: #999999;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>