<template>
	<div class="container">
		<!-- 语言选择区 -->
		<div class="top">
			<div class="languageTitle">Language</div>
		</div>
		<div class="flexCenter">
			<img
				style="height: 145px; width: 127px; margin-top: 22px; position: relative; z-index: 2"
				src="../../src/assets/images/common/logo.jpg"
				alt=""
			/>
		</div>
		<div class="select flexBetween" @click="showLanguage">
			<div class="flexCenter">
				<img class="language" src="../../src/assets/images/common/language.png" alt="" />
				<div>{{ choose }}</div>
			</div>
			<img v-show="!show" class="righticon" src="../../src/assets/images/common/right.png" alt="" />
			<img v-show="show" class="righticon" src="../../src/assets/images/common/down.png" alt="" />
		</div>
		<div class="goBtn flexCenter" @click="goNext">
			<div>Continue</div>
			<img src="../../src/assets/images/common/go.png" alt="" />
		</div>
		<img class="br" src="../../src/assets/images/common/zaodian.jpg" />
		<van-popup
			v-model:show="show"
			position="bottom"
			:overlay-style="{ background: 'rgba(0, 0, 0, 0)' }"
		>
			<div class="itemRow">
				<div
					class="flexBetween"
					:class="choose == item.label ? 'lanItemA' : 'lanItem'"
					v-for="item in options"
					:key="item.value"
					@click="languageChange(item)"
				>
					<div class="left">{{ item.label }}</div>
					<div class="right">{{ item.li }}</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();
const { locale, t } = proxy.$useI18n();
const value = ref(localStorage.getItem("language") || "CN");
const choose = ref(localStorage.getItem("languageName") || "简体中文");
const show = ref(false);
const options = [
	{
		li: "Aa",
		value: "EN",
		label: "English",
	},
	{
		li: "あ",
		value: "JPN",
		label: "日本語",
	},
	{
		li: "简",
		value: "CN",
		label: "简体中文",
	},
	{
		li: "繁",
		value: "TW",
		label: "繁體中文",
	},
];
const goNext = () => {
	if (localStorage.getItem("language")) {
		localStorage.setItem("language", value.value);
		localStorage.setItem("languageName", choose.value);
		locale.value = value.value;
		router.go(-1);
	} else {
		localStorage.setItem("language", value.value);
		localStorage.setItem("languageName", choose.value);
		locale.value = value.value;
		router.replace("/");
	}
};
const showLanguage = () => {
	show.value = true;
};
const languageChange = (e) => {
	if (e.label == choose.value) {
		return;
	}
	options.map((item) => {
		if (item.value == e.value) {
			choose.value = item.label;
			value.value = e.value;
		}
	});
	show.value = false;
};
</script>

<style lang="less" scoped>
* {
	box-sizing: border-box;
}
.container {
	width: 100%;
	height: 100%;
	position: relative;
}
.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
.top {
	flex-direction: column;
	padding-top: 15px;
	z-index: 2;
	position: relative;
}
.languageTitle {
	font-size: 24px;
	color: rgba(255, 255, 255, 1);
	padding-left: 15px;
	font-weight: 800;
}
.select {
	margin-top: 50px;
	padding: 0px 15px 0px 10px;
	height: 48px;
	margin: 43px 22px 0px 22px;
	font-size: 14px;
	color: rgba(255, 255, 255, 1);
	font-weight: 500;
	position: relative;
	z-index: 2;
	.language {
		width: 18px;
		height: 18px;
		margin-right: 10px;
	}
	.righticon {
		width: 12px;
		height: 12px;
	}
}
.select::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 0.5px solid rgba(102, 102, 102, 1);
	pointer-events: none;
}
.bottom {
	position: absolute;
	bottom: 60px;
}
.goBtn {
	position: relative;
	z-index: 2;
	margin-top: 25px;
	font-weight: 500;
	font-size: 16px;
	color: #dbce5c;
	line-height: 19px;
	text-decoration-line: underline;
	img {
		width: 20px;
		height: 20px;
		margin-left: 8px;
	}
}
.br {
	position: absolute;
	z-index: 1;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
.noneBG {
	opacity: 0;
}
:deep(.van-popup) {
	background: rgba(255, 255, 255, 0);
	padding: 20px 15px 35px 15px;
	background-image: url("../../src/assets/images/common/popbg.jpg");
	background-size: cover;
	position: absolute;
}
.itemRow {
	display: flex;
	flex-wrap: wrap;
}
.lanItem {
	width: calc((100% - 15px) / 2);
	border: 1px solid rgba(107, 107, 107, 1);
	padding: 12px;
	margin-top: 15px;
	.left {
		font-weight: 500;
		font-size: 14px;
		color: #6b6b6b;
		line-height: 16px;
	}
	.right {
		font-weight: 800;
		font-size: 48px;
		color: #ffffff;
		line-height: 56px;
	}
}
.lanItemA {
	width: calc((100% - 15px) / 2);
	padding: 12px;
	margin-top: 15px;
	border: 1px solid #dbce5c;
	.left {
		font-weight: 500;
		font-size: 14px;
		color: #dbce5c;
		line-height: 16px;
	}
	.right {
		font-weight: 800;
		font-size: 48px;
		color: #dbce5c;
		line-height: 56px;
	}
}
.lanItem:nth-child(2n),
.lanItemA:nth-child(2n) {
	margin-left: 15px;
}
</style>
