import { App } from 'vue';

interface ThrottleDirectiveBinding {
 value: {
  fn: () => void;
  time: number;
 };
}

export default function (app: App): void {
 app.directive('throttle', {
  mounted(el: any, binding: ThrottleDirectiveBinding) {
   const { fn, time } = binding.value;

   if (typeof fn !== 'function') return;

   el._flag = true; // 开关默认为开
   el._timer = null;

   el.handler = function () {
    if (!el._flag) return;

    // 执行之后开关关闭
    el._flag && fn();
    el._flag = false;

    if (el._timer !== null) {
     clearTimeout(el._timer);
     el._timer = null;
    }

    el._timer = setTimeout(() => {
     el._flag = true; // 指定时间后开关开启
    }, time);
   };

   el.addEventListener('click', el.handler);
  },
  unmounted(el: any) {
   el.removeEventListener('click', el.handler);
  },
 });
}